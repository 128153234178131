import { InsightsRequest } from 'Types/insightstypes';
import http from 'Utils/Http';

const fetchAggregatedData = async (params: InsightsRequest) => {
  const { data } = await http.post('/api/insights/aggregateddata', { ...params });

  return data;
};

export default fetchAggregatedData;
