import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useInsightsQuery } from '../../../../Context/InsightsQueryContext';

const IncludeBrandSwitch = () => {
  const { includeBrandCampaigns, setIncludeBrandCampaigns } = useInsightsQuery();
  const handleIncludeBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIncludeBrandCampaigns(checked);
    includeBrandCampaigns !== checked && setIncludeBrandCampaigns(checked);
  };
  return (
    <FormControlLabel
      control={<Switch checked={includeBrandCampaigns} onChange={handleIncludeBrand} />}
      label="Include brand"
    />
  );
};

export default IncludeBrandSwitch;
