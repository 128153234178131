import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { LIGHTBLUE, ORANGE } from 'Utils/colors';
import React, { useEffect, useRef, useState, useTransition } from 'react';
import DateRangeDialog from '../DateRangeDialog';
import { Alert, Typography } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';

const rangeOptions = {
  'Last 14 Days': {
    responsive: true,
    start: dayjs().subtract(14, 'day').toDate(),
    end: dayjs().subtract(1, 'day').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 14 days': {
        start: dayjs(startdate).subtract(14, 'day').toDate(),
        end: dayjs(enddate).subtract(14, 'day').toDate(),
      },
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').toDate(),
        end: dayjs(enddate).subtract(1, 'year').toDate(),
      },
    }),
  },
  'This Month': {
    responsive: true,
    start: dayjs().startOf('month').toDate(),
    end: dayjs().subtract(1, 'day').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 1 month': {
        start: dayjs(startdate).subtract(1, 'month').startOf('month').toDate(),
        end: dayjs(enddate).subtract(1, 'month').toDate(),
      },
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').startOf('month').toDate(),
        end: dayjs(enddate).subtract(1, 'year').toDate(),
      },
    }),
  },
  'Last 30 Days': {
    responsive: true,
    start: dayjs().subtract(30, 'day').toDate(),
    end: dayjs().subtract(1, 'day').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 30 days': {
        start: dayjs(startdate).subtract(30, 'day').toDate(),
        end: dayjs(enddate).subtract(30, 'day').toDate(),
      },
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').toDate(),
        end: dayjs(enddate).subtract(1, 'year').toDate(),
      },
    }),
  },

  'Last 90 Days': {
    responsive: false,
    start: dayjs().subtract(90, 'day').toDate(),
    end: dayjs().subtract(1, 'day').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 90 days': {
        start: dayjs(startdate).subtract(90, 'day').toDate(),
        end: dayjs(enddate).subtract(90, 'day').toDate(),
      },
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').toDate(),
        end: dayjs(enddate).subtract(1, 'year').toDate(),
      },
    }),
  },
  'This Year': {
    responsive: false,
    start: dayjs().startOf('year').toDate(),
    end: dayjs().subtract(1, 'day').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').startOf('year').toDate(),
        end: dayjs(enddate).subtract(1, 'year').endOf('year').toDate(),
      },
    }),
  },
  'Last Year': {
    responsive: false,
    start: dayjs().subtract(1, 'year').startOf('year').toDate(),
    end: dayjs().subtract(1, 'year').endOf('year').toDate(),
    comparisonOptions: (startdate: string, enddate: string) => ({
      '- 1 year': {
        start: dayjs(startdate).subtract(1, 'year').startOf('year').toDate(),
        end: dayjs(enddate).subtract(1, 'year').endOf('year').toDate(),
      },
    }),
  },
};

const customDateOptions = (startdate: string, enddate: string) => ({
  comparisonOptions: {
    '- 1 month': {
      start: dayjs(startdate).subtract(1, 'month').toDate(),
      end: dayjs(enddate).subtract(1, 'month').toDate(),
    },
    '- 1 year': {
      start: dayjs(startdate).subtract(1, 'year').toDate(),
      end: dayjs(enddate).subtract(1, 'year').toDate(),
    },
  },
});

type ComparisonOption = (typeof rangeOptions)[keyof typeof rangeOptions]['comparisonOptions'];

function DateRangeSelector() {
  const { compareEnabled } = useInsightsQuery();
  const [isPending, startTransition] = useTransition();
  const [buttonText, setButtonText] = useState('Select to Compare');
  const [selectedDateRange, setSelectedDateRange] = useState('This Month');
  const [comparisonRange, setComparisonRange] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCustomDateRange, setOpenCustomDateRange] = useState(false);

  const matches = useMediaQuery('(min-width:600px)');

  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);
  const {
    dateRange,
    setDateRange,
    shallCompare,
    setShallCompare,
    setAlternateDateRange,
    startDate,
    endDate,
    alternateStartDate,
    alternateEndDate,
  } = useInsightsQuery();

  function handleDateRangeSelect(event: React.MouseEvent<HTMLElement>, newDateRange: string) {
    if (newDateRange === 'Custom Date Range' && !openCustomDateRange) {
      setOpenCustomDateRange(true);
      setComparisonRange(null);
      setShallCompare(false);
      setSelectedDateRange(newDateRange);
    } else if (rangeOptions[newDateRange]) {
      // Checking if newDateRange is a valid key in rangeOptions.
      const { start, end } = rangeOptions[newDateRange as keyof typeof rangeOptions];
      setComparisonRange(null);
      setShallCompare(false);
      setButtonText('Select to Compare');
      setSelectedIndex(0);
      setSelectedDateRange(newDateRange);
      setDateRange([start, end]);
    }
  }

  useEffect(() => {
    if (!shallCompare) setButtonText('Select to Compare');
    if (!shallCompare) setComparisonRange(null);
  }, [shallCompare]);

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleShallCompareSwitch(event: React.MouseEvent<HTMLElement>) {
    startTransition(() => {
      if (selectedDateRange === 'Custom Date Range' && !openCustomDateRange) {
        setShallCompare(!shallCompare);
        setButtonText(Object.keys(customDateOptions(startDate, endDate).comparisonOptions)[0]);
        setComparisonRange(Object.keys(customDateOptions(startDate, endDate).comparisonOptions)[0]);
      }
      if (selectedDateRange !== 'Custom Date Range' && !openCustomDateRange) {
        setShallCompare(!shallCompare);
        setButtonText(
          Object.keys(
            rangeOptions[selectedDateRange as keyof typeof rangeOptions].comparisonOptions(startDate, endDate)
          )[0]
        );
        const option = Object.keys(
          rangeOptions[selectedDateRange as keyof typeof rangeOptions].comparisonOptions(startDate, endDate)
        )[0];
        setComparisonRange(option);
        const { start, end } =
          selectedDateRange === 'Custom Date Range'
            ? customDateOptions(startDate, endDate).comparisonOptions[option as keyof ComparisonOption]
            : rangeOptions[selectedDateRange as keyof typeof rangeOptions].comparisonOptions(startDate, endDate)[
                option as keyof ComparisonOption
              ];
        setAlternateDateRange([start, end]);
      }
    });
  }

  function handleMenuItemClick(event: React.MouseEvent<HTMLElement>, index: number, option: string) {
    setSelectedIndex(index);
    setOpen(false);
    setButtonText(option);
    setShallCompare(true);
    setComparisonRange(option);
    const { start, end } =
      selectedDateRange === 'Custom Date Range'
        ? customDateOptions(startDate, endDate).comparisonOptions[option as keyof ComparisonOption]
        : rangeOptions[selectedDateRange as keyof typeof rangeOptions].comparisonOptions(startDate, endDate)[
            option as keyof ComparisonOption
          ];
    setAlternateDateRange([start, end]);
  }

  function handleCloseCustomDateRange() {
    setOpenCustomDateRange(false);
  }

  const hasLongDateRange = dayjs(dateRange[1]).diff(dayjs(dateRange[0]), 'day') > 200;
  const isEndDateWithinRange = dayjs(endDate).isAfter(dayjs().subtract(7, 'days'));

  return (
    <Grid container direction="column" justifyContent="start" alignItems="start" spacing={2}>
      <Grid item sx={{ marginBottom: '12px' }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
          justifyContent="start"
          alignContent={{ xs: 'end', md: 'start' }}
        >
          <ToggleButtonGroup
            color="primary"
            value={selectedDateRange}
            onChange={(e, val) => handleDateRangeSelect(e, val)}
            exclusive
            aria-label="Date range"
          >
            {Object.entries(rangeOptions).map(
              ([key]) =>
                (rangeOptions[key as keyof typeof rangeOptions].responsive || matches) && (
                  <ToggleButton key={key} value={key} size="small" onClick={handleDateRangeSelect}>
                    {key}
                  </ToggleButton>
                )
            )}
            <ToggleButton
              key="Custom Date Range"
              value="Custom Date Range"
              size="small"
              onClick={handleDateRangeSelect}
            >
              <CalendarMonthIcon />
              <ClickAwayListener onClickAway={handleCloseCustomDateRange}>
                <DateRangeDialog open={openCustomDateRange} onClose={handleCloseCustomDateRange} />
              </ClickAwayListener>
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonGroup variant="outlined" aria-label="split button" sx={{ width: '250px' }} ref={anchorRef}>
            <Button
              disabled={!compareEnabled}
              variant="outlined"
              onClick={handleShallCompareSwitch}
              sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                borderColor: 'rgba(0, 0, 0, 0.12)',
                fontSize: '0.8125rem',
              }}
            >
              <Switch
                sx={{
                  transform: 'translate3d(-3px, -1px, 0px)',
                  fontSize: '0.8125rem',
                }}
                checked={shallCompare}
                size="small"
                disabled={!compareEnabled}
              />
              <span style={{ marginLeft: '-4px' }}>{buttonText}</span>
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                borderColor: 'rgba(0, 0, 0, 0.12)',
                fontSize: '0.8125rem',
              }}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              disabled={!compareEnabled}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => {
              const menuoptions =
                selectedDateRange === 'Custom Date Range'
                  ? Object.keys(customDateOptions(startDate, endDate).comparisonOptions)
                  : Object.keys(
                      rangeOptions[selectedDateRange as keyof typeof rangeOptions]?.comparisonOptions(
                        startDate,
                        endDate
                      ) || {}
                    );
              return (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper sx={{ width: '200px' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {menuoptions?.map((option, index) => {
                          return (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index, option)}
                            >
                              {option || 'error'}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              );
            }}
          </Popper>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={0}>
            <Stack spacing={1} direction="row">
              <h5 style={{ fontWeight: 'bold' }}>Period</h5>
              {hasLongDateRange && (
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '1rem' }}>
                      A long date range can possibly be slow to render. Please have patience.
                    </Typography>
                  }
                  arrow
                >
                  <InfoIcon sx={{ position: 'relative', top: '-3px', fontSize: '22px', color: ORANGE }} />
                </Tooltip>
              )}
            </Stack>
            <p>
              {`${dayjs(startDate).format('YYYY-MM-DD')} - ${dayjs(endDate).format('YYYY-MM-DD')}`}{' '}
              {isEndDateWithinRange && (
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '1rem' }}>
                      Please keep in mind that when you select to view very recent data, there might be a slight delay
                      in the availability of the most up-to-date information. This delay can impact the reported
                      metrics, potentially resulting in lower figures than the actual performance.
                    </Typography>
                  }
                  arrow
                >
                  <InfoIcon sx={{ position: 'relative', top: '-3px', fontSize: '22px', color: LIGHTBLUE }} />
                </Tooltip>
              )}
            </p>
          </Stack>
          {shallCompare && (
            <Stack direction="column" spacing={0}>
              <h5 style={{ fontWeight: 'bold' }}>Comparison Period</h5>
              <p>{`${dayjs(alternateStartDate).format('YYYY-MM-DD')} - ${dayjs(alternateEndDate).format(
                'YYYY-MM-DD'
              )}`}</p>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default DateRangeSelector;
