export const RED = `rgba(173, 30, 30, 1)`;
export const ORANGE = `rgba(252, 86, 7, 1)`;
export const YELLOW = `rgba(255, 225, 40, 1)`;
export const PINK = `rgba(254, 140, 181, 1)`;
export const BLACK = `rgba(0, 0, 0, 1)`;
export const TEAL = `rgba(31, 150, 154, 1)`;
export const BLUE = `rgba(0, 111, 255, 1)`;
export const LIGHTBLUE = `rgba(70,190,255, 1)`;
export const BEIGE = `rgba(220,216,205, 1)`;

export const LIGHTPINK = 'rgba(254, 175, 204, 1)';
export const LIGHTTEAL = 'rgba(0, 255, 255, 1)';
export const LIGHTORANGE = 'rgba(255, 153, 51, 1)';

export const LIGHTBEIGE = 'rgba(236, 235, 229, 1)';

export const COLORS = [LIGHTPINK, LIGHTTEAL, LIGHTORANGE, ORANGE, YELLOW, PINK, BLACK, TEAL, BLUE, LIGHTBLUE];
