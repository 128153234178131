import React, { useRef } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

function AdmittanceErrorListView({ errors }) {
  const containerRef = React.useRef(null);
  return errors.length > 0 ? (
    <Box ref={containerRef}>
      <Slide direction="down" in timeout={800} container={containerRef.current}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <ul>
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </Alert>
        </Stack>
      </Slide>
    </Box>
  ) : null;
}

export default AdmittanceErrorListView;
