import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

type Props = {};

const loadingMessages = ['Loading', 'Please wait', 'Calculating', 'Sorting', 'Filtering'];
const LoadingScreen = (props: Props) => {
  const [loadingMessage, setLoadingMessage] = React.useState(loadingMessages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessage(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);
    }, 1200);
    return () => clearInterval(interval);
  });

  return (
    <Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          width: '100%',
        }}
      >
        <Stack direction="column" spacing={1} justifyContent="center" alignContent="center" alignItems="center">
          <CircularProgress />
          <p style={{ fontSize: '80%', letterSpacing: '1px' }}>{loadingMessage.toUpperCase()}</p>
        </Stack>
      </Box>
    </Grid>
  );
};

export default LoadingScreen;
