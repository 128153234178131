import { useEffect, useState, useMemo } from 'react';
import { AggregatedDataByDateType, InsightsRequest } from '../Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { DateSelectorConfig } from 'Context/InsightsQueryContext';

interface Datum {
  x?: Date | number | string;
  y?: Date | number | string;
  [key: string]: any;
}

type DataPerCategoryType = {
  [key: string]: Serie;
};

interface Serie {
  id: string | number;
  sortKey?: string | number;
  data: Datum[];
  [key: string]: any;
}
type TimeSeries = [AggregatedDataByDateType[], AggregatedDataByDateType[]];
type DataGetter = (
  accumulator: {
    [key: string]: Serie;
  },
  currentValue: AggregatedDataByDateType,
  currentIndex: number,
  array: AggregatedDataByDateType[],
  isOriginal: boolean
) => { [key: string]: Serie };
type Props = [...TimeSeries, DataGetter, InsightsRequest];
type Error = string[];

/**
 *
 * @param data
 * @param data[0] originalTimeSeries is the time series that is used as the base for the comparison.
 * @param data[1] comparisonTimeSeries is the time series that is compared to the original time series.
 * @param data[2] dataGetter is a function that is used in a reducer function to get the data per category from the time series.
 * @returns
 *
 * @example
 * const [originalTimeSeries, comparisonTimeSeries, dataGetter, dateSelectorConfig] = data;
 * const [originalData, comparisonData, error] = useComparisonData([originalTimeSeries, comparisonTimeSeries, dataGetter, dateSelectorConfig]);
 *
 * @description
 * This hook takes in two time series and a data getter function and returns two objects with the data per category.
 * The data getter function is used in a reducer function to get the data per category from the time series.
 * @param accumulator: The accumulator is an object with the data per category. The data per category is stored in the data property of the category object.
 * @param currentValue: The current value is the current data point in the time series.
 * @param currentIndex: The current index is the index of the current data point in the time series.
 * @param array: The array is the time series.
 * @param isOriginal: The isOriginal parameter is a boolean that indicates if the time series is the original time series or the comparison time series. This is useful for displaying the correct labels in the chart.
 *
 * The data getter function should return an object with the data per category.
 * The data per category is stored in the data property of the category object.
 *
 * @example
 * const dataGetter = (accumulator, currentValue, currentIndex, array, isOriginal) => {
 * const { reportdate, ...rest } = currentValue;
 * const day = dayjs(reportdate).format('D');
 * const category = rest[categoryName];
 * const categoryData = accumulator[category] || {
 *  id: category,
 * data: [],
 * };
 * categoryData.data.push({ x: day, y: rest[metricName] });
 * accumulator[category] = categoryData;
 * return accumulator;
 * };
 *
 * @returns
 * @returns result: An array with two objects with the data per category.
 * @returns result[0]: The first object with the data per category.
 * @returns result[1]: The second object with the data per category.
 * @returns error: An array with errors.
 *
 *
 */

function useComparisonData(data: Props): [DataPerCategoryType, DataPerCategoryType, ...Error] {
  const [originalTimeSeries, comparisonTimeSeries, dataGetter, queryparams] = data;
  const { reports: [{ includebrandcampaigns }] = [{}] } = queryparams;

  const [errors, setErrors] = useState<Error>([]);

  useEffect(() => {
    if (comparisonTimeSeries?.length === 0) {
      setErrors((previousErrors) => {
        if (!previousErrors.includes('No comparison data found.')) {
          return [...previousErrors, 'No comparison data found.'];
        }
        return previousErrors;
      });
    }
  }, [comparisonTimeSeries?.length]);

  useEffect(() => {
    if (originalTimeSeries?.length === 0) {
      setErrors((previousErrors) => {
        if (!previousErrors.includes('No original data found.')) {
          return [...previousErrors, 'No original data found'];
        }
        return previousErrors;
      });
    }
  }, [originalTimeSeries?.length]);

  const result: [DataPerCategoryType, DataPerCategoryType] = useMemo(() => {
    if (!data || (originalTimeSeries?.length === 0 && comparisonTimeSeries?.length === 0)) return;

    const org = originalTimeSeries?.reduce(
      (acc, curr, i, arr) => dataGetter(acc, curr, i, arr, true),
      {} as DataPerCategoryType
    );

    const comp = comparisonTimeSeries?.reduce(
      (acc, curr, i, arr) => dataGetter(acc, curr, i, arr, false),
      {} as DataPerCategoryType
    );

    return [org, comp];
  }, [originalTimeSeries?.length, comparisonTimeSeries?.length, includebrandcampaigns]);

  return [...result, ...errors];
}

export default useComparisonData;
