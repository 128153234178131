import Grid from '@mui/material/Grid';
import { BLACK, ORANGE, TEAL } from 'Utils/colors';
import React from 'react';
import AnimatedLogo from '../AnimatedLogo';

import MicrosoftLogoContainer from '../MicrosoftLogo/MicrosoftLogo';
import PartnerLogoContainer from '../PartnerLogo';
import './Footer.css';

import Stack from '@mui/material/Stack';

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: ORANGE,
      }}
    >
      {/*Animated Logo and Philosophy Grid*/}
      <Grid
        container
        spacing={3}
        sx={{
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '12px',
          paddingBottom: '36px',
        }}
      >
        <Grid item xs={12} md={8}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '12px',
            }}
          >
            <AnimatedLogo width={50} />
            <div
              style={{
                color: 'black',
                fontSize: '12px',
                fontWeight: 600,
                marginTop: '5px',
              }}
            >
              OUR PHILOSOPHY:
            </div>

            <div
              style={{
                color: 'black',
                fontSize: '35px',
                fontWeight: 'bold',
                lineHeight: '1',
                marginBottom: '5px',
                marginTop: '5px',
              }}
            >
              Dance to the algorithms!
            </div>
          </div>
        </Grid>
        {/*Navigation Links Grid*/}
        <Grid item xs={12} md={4}>
          <div style={{ borderTop: '1px solid black' }}>
            <ul
              style={{
                listStyleType: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                paddingLeft: '0',
                lineHeight: '42px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <li
                style={{
                  borderBottom: '1px solid black',
                  fontSize: '14px',
                  marginLeft: '0',
                }}
              >
                <a target="_blank" href="https://amandaai.com/contact-us/" className="link">
                  <span> &#10132; Contact us </span>
                </a>
              </li>

              <li style={{ borderBottom: '1px solid black', fontSize: '14px', marginLeft: '0' }}>
                <a target="_blank" href="https://amandaai.com/resources/faq/" className="link">
                  <span> &#10132; FAQ </span>
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>

      {/*Microsoft and Partner Logos Grid*/}

      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: BLACK,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
        >
          <Grid item>
            <Stack direction="row" spacing={3}>
              <MicrosoftLogoContainer />
              <PartnerLogoContainer />
            </Stack>
          </Grid>
          <Grid item>
            <nav className="col-sm" style={{ padding: '5px' }}>
              <a
                target="_blank"
                href="https://www.iubenda.com/privacy-policy/89530034/cookie-policy"
                style={{ color: 'rgb(153,153,153', marginRight: '20px' }}
              >
                &#10132; Cookie Policy
              </a>
              <a
                target="_blank"
                href="https://www.iubenda.com/privacy-policy/89530034/full-legal"
                style={{ color: 'rgb(153,153,153', marginRight: '10px' }}
              >
                &#10132; Privacy Policy
              </a>
            </nav>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
