import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: '0.7rem',
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? '#9ec8ff' : '#9ec8ff'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#006fff' : '#006fff',
  },
}));

function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <div className="logo" style={{ margin: '0.7em 0' }}>
          <img
            src="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
            srcSet="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
            alt="Amanda AI logotype"
            loading="lazy"
            width={180}
            style={{ filter: 'invert(1)' }}
          />
          <BorderLinearProgress variant="indeterminate" />
        </div>
      </div>
    </div>
  );
}

export default Loading;
