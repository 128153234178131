import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

type helpModalProps = {
  helptext: string;
  helpTextTitle?: string;
};

function HelpModal({ helptext, helpTextTitle = null }: helpModalProps) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton aria-label="help">
        <HelpIcon onClick={() => setOpen(true)} />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {helpTextTitle || 'Help'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {helptext}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default HelpModal;
