import axios from 'axios';
import { getCookie } from 'Utils/Helpers';
import http from 'Utils/Http';

export const fetchAllOrganizations = async () => {
  const { data } = await axios.get('/api/organizations');
  return data;
};
export const fetchUserOrganizations = async () => {
  const { data } = await http.get('/api/userorganizations');
  return data;
};

export const fetchAllPartnerOrganizations = async () => {
  const { data } = await axios.get('/api/parentorganizationlist');
  return data;
};

export const fetchDescendantOrganizations = async (uuid) => {
  const { data } = await axios.get(`/api/descendantorganizationlist/${uuid}`);
  return data;
};

export const saveOrganization = async (organization = undefined, args = undefined) => {
  const { data } = await axios.patch(`/api/organization/${organization.id}/`, args, {
    headers: { 'X-CSRFToken': getCookie('csrftoken') },
  });
  return data;
};

export const passToStartDeliver = async (organizationId, args = {}) => {
  const { data } = await axios.post(`/api/addorganizationtostartdeliver/${organizationId}/`, args, {
    headers: { 'X-CSRFToken': getCookie('csrftoken') },
  });
  return data;
};
