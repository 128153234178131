// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { LIGHTBLUE, PINK, YELLOW, BLUE, LIGHTBEIGE, TEAL } from 'Utils/colors';
import { humanReadable, HumanReadableInsightsKPIsType } from 'Utils/humanReadableTitles';
import React from 'react';
import * as d3 from 'd3';
import EastIcon from '@mui/icons-material/East';
import Stack from '@mui/material/Stack';
import { CampaignTypeData, PortalUser, CampaignType } from 'Types/insightstypes';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { BasicTooltip } from '@nivo/tooltip';

type Props = {
  chartData: chartData;
};

type chartData = [
  {
    id: keyof CampaignTypeData;
    label: keyof CampaignTypeData;
  } & { [key in CampaignType]?: number }
];

const CustomTooltip = (props: Omit<BarTooltipProps<chartData>, 'hidden' | 'index' | 'indexValue' | 'data'>) => (
  <BasicTooltip
    id={
      <span>
        <strong>{props.id}</strong> : {props.formattedValue}
      </span>
    }
    enableChip={true}
    color={props.color}
  />
);

const NivoBarChart = ({ chartData }: Props) => {
  const { portalUser } = useInsightsQuery();
  const domaindata: number[] = Object.values(chartData[0]).filter(
    (value): value is number => typeof value === 'number'
  );
  const domain = [0, d3.max(domaindata)];
  const scale = d3.scaleLinear().domain(domain).range([0, 100]);
  const ticks = scale.ticks(3);

  const itemHasValues = domaindata.map((item) => item >= 0).includes(true);
  const keys = Object.keys(chartData[0]).filter((key) => key !== 'id' && key !== 'label' && key !== 'key');

  const colors = [PINK, BLUE, YELLOW, TEAL];

  return itemHasValues ? (
    <ResponsiveBar
      data={chartData}
      keys={keys}
      indexBy="key"
      margin={{ top: 50, right: 120, bottom: 30, left: 40 }}
      padding={0.2}
      innerPadding={5}
      groupMode="grouped"
      layout="vertical"
      valueScale={{ type: 'linear' }}
      valueFormat={
        chartData[0]?.label
          ? humanReadable.insightsKPIValueFormat[chartData[0]?.label as keyof Omit<CampaignTypeData, 'campaigntype'>](
              portalUser
            )
          : ' >-.2r'
      }
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: ticks,
        legendPosition: 'middle',
        legendOffset: 32,
        format: function (e) {
          return d3.format(
            humanReadable.insightsKPIValueFormat[chartData[0]?.label as keyof Omit<CampaignTypeData, 'campaigntype'>](
              portalUser
            )
          )(e);
        },
      }}
      axisBottom={null}
      enableGridX={false}
      enableGridY={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 2.2]],
      }}
      legends={[
        {
          dataFrom: 'keys',
          data: keys.map((key, index) => ({
            id: key,
            label: key === 'PERFORMANCE_MAX' ? 'PMAX' : key,
            color: colors[index] as string,
          })),
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      isFocusable={true}
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
      }}
      tooltip={(node) => (
        <CustomTooltip
          color={node.color}
          id={node.id}
          label={node.label}
          value={node.value}
          formattedValue={node.formattedValue}
        />
      )}
    />
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80%',
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={1}>
        <EastIcon fontSize="small" />
        <div>Not enough data to draw chart</div>
      </Stack>
    </div>
  );
};

export default NivoBarChart;