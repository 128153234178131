import axios from 'axios';
import { getCookie } from 'Utils/Helpers';

const headers = { headers: { 'X-CSRFToken': getCookie('csrftoken') } };

const admitUser = async (key) => {
  if (key === undefined) throw ReferenceError('Admittance key error');
  if (key === null) throw ReferenceError('Admittance key error');
  if (key === '') throw ReferenceError('Admittance key error');
  const { data } = await axios.get(`/api/admit/${key}`);
  return data;
};

const passwordReset = async (args) => {
  const { data } = await axios.post(`/api/admit/passwordreset`, args, headers);
  return data;
};

export { admitUser, passwordReset };
