import { PortalUser } from 'Types/insightstypes';

export const humanReadable = Object.freeze({
  insightsKPIs: Object.freeze({
    keyword: (portaluser: PortalUser = undefined) => 'Keyword',
    reportdate: (portaluser: PortalUser = undefined) => 'Report Date',
    index: (portaluser: PortalUser = undefined) => '-',
    clicks: (portaluser: PortalUser = undefined) => 'Clicks',
    clickthroughrate: (portaluser: PortalUser = undefined) => 'CTR',
    conversionrate: (portaluser: PortalUser = undefined) => 'Conversion Rate',
    convrate: (portaluser: PortalUser = undefined) => 'Conversion Rate',
    conversions: (portaluser: PortalUser = undefined) => 'Conversions',
    conversionvalue: (portaluser: PortalUser = undefined) => 'Conversion Value',
    cost: (portaluser: PortalUser = undefined) => 'Cost',
    frequency: (portaluser: PortalUser = undefined) => 'Frequency',
    impressions: (portaluser: PortalUser = undefined) => 'Impressions',
    impressionshare: (portaluser: PortalUser = undefined) => 'Impression Share',
    searchimpressionshare: (portaluser: PortalUser = undefined) => 'Search Imp. Share',
    landingpageviews: (portaluser: PortalUser = undefined) => 'Landing Page Views',
    newusers: (portaluser: PortalUser = undefined) => 'New Users',
    returningusers: (portaluser: PortalUser = undefined) => 'Returning Users',
    returnonexpenditurevalue: (portaluser: PortalUser = undefined) =>
      portaluser?.return_on_expenditure_type ? portaluser?.return_on_expenditure_type : 'Ret. On Exp. Value',
    roas: (portaluser: PortalUser = undefined) =>
      portaluser?.return_on_expenditure_type ? portaluser?.return_on_expenditure_type : 'Ret. On Exp. Value',
    searchvolume: (portaluser: PortalUser = undefined) => 'Search Volume',
    uniqueusers: (portaluser: PortalUser = undefined) => 'Unique Users',
    qualityscore: (portaluser: PortalUser = undefined) => 'Quality Score',
    costperclick: (portaluser: PortalUser = undefined) => 'CPC',
    landingpageexperience: (portaluser: PortalUser = undefined) => 'Landing Page Exp.',
  }),
  insightsKPIValueFormat: Object.freeze({
    keyword: (portaluser: PortalUser = undefined) => '-',
    reportdate: (portaluser: PortalUser = undefined) => '', // percentage, 0 decimals
    index: (portaluser: PortalUser = undefined) => '-',
    clicks: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    clickthroughrate: (portaluser: PortalUser = undefined) => '0>-01,.2%', // percentage, 2 decimals
    conversionrate: (portaluser: PortalUser = undefined) => '0>-01,.2%', // percentage, 2 decimals
    convrate: (portaluser: PortalUser = undefined) => '0>-01,.2%', // percentage, 2 decimals
    conversions: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    conversionvalue: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    cost: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    frequency: (portaluser: PortalUser = undefined) => '0>-01,.2f', // number, 2 decimals
    impressions: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    impressionshare: (portaluser: PortalUser = undefined) => ' >-.0p', // percentage, 0 decimals
    searchimpressionshare: (portaluser: PortalUser = undefined) => ' >-.0p', // percentage, 0 decimals
    landingpageviews: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    newusers: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    returningusers: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    returnonexpenditurevalue: (portaluser: PortalUser = undefined) => '0>-01,.2f', // number, 2 decimals
    roas: (portaluser: PortalUser = undefined) => '0>-01,.2f', // number, 2 decimals
    searchvolume: (portaluser: PortalUser = undefined) => '0>-01,.2f', // number, 0 decimals
    uniqueusers: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
    qualityscore: (portaluser: PortalUser = undefined) => '0>-01,.1f', // number, 1 decimals
    costperclick: (portaluser: PortalUser = undefined) => '0>-01,.2f', // number, 2 decimals
    landingpageexperience: (portaluser: PortalUser = undefined) => '0>-01,.3~s', // number, 0 decimals
  }),
});

export const insightsKPIWithCurrencyCode = Object.freeze(['cost', 'conversionvalue', 'costperclick', 'cpc']);

export type HumanReadableInsightsKPIsType = typeof humanReadable.insightsKPIs;
export type HumanReadableInsightsKPIsValueFormatType = typeof humanReadable.insightsKPIValueFormat;
