import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import helptexts, { HelpTextType } from 'Assets/JSON/helptexts';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import fetchCampaignTypeData from 'Services/InsightsServices/fetchCampaignTypeData';
import { CampaignType, CampaignTypeData } from 'Types/insightstypes';
import { BEIGE } from 'Utils/colors';
import { HumanReadableInsightsKPIsType, humanReadable } from 'Utils/humanReadableTitles';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import ErrorBoundary from './Components/ErrorBoundary/index';
import HelpModal from './Components/HelpModal';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import useChartDimensions from 'Hooks/useChartDimensions';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import LoadingScreen from './Components/LoadingScreen';
import NivoBarChart from './Components/NivoBarChart';

type Props = { helptext?: string };

interface chartData extends CampaignTypeData {
  key: string;
}

type BarChartData = {
  [key in CampaignType]?: number;
};

const chartSettings = {
  marginTop: 30,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 40,
};

const metricSortOrder = [
  'roas',
  'returnonexpenditurevalue',
  'cpa',
  'cost',
  'searchvolume',
  'conversionvalue',
  'convrate',
  'conversionrate',
  'clicks',
  'cpc',
  'costperconversion',
  'costperclick',
  'impressions',
  'conversions',
  'searchimpressionshare',
  'impressionshare',
  'ctr',
  'clickthroughrate',
  'frequency',
  'landingpageviews',
  'newusers',
  'returningusers',
  'uniqueusers',
];

const CampaignTypeDataBarChartView = ({ helptext = '' }: Props) => {
  const { portalUser, setPlatformsEnabled, setCompareEnabled } = useInsightsQuery();
  const { queryparams, isReadyToRun, shallCompare, alternateStartDate, alternateEndDate } = useInsightsQuery();
  const [ref, dms] = useChartDimensions(chartSettings);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['campaigntypedata', { ...queryparams }],
    queryFn: () => fetchCampaignTypeData(queryparams),
    enabled: isReadyToRun,
  });

  const {
    data: comparisonData,
    isLoading: isLoadingComparisonData,
    isFetching: isFetchingComparisonData,
  } = useQuery({
    queryKey: ['campaigntypedata', { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate }],
    queryFn: () =>
      fetchCampaignTypeData({
        ...queryparams,
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
    enabled: isReadyToRun && shallCompare,
  });

  const chartData: CampaignTypeData[] = data?.data?.data || [];

  const superData = useMemo(() => {
    if (chartData.length === 0) return [];
    const allkeys = chartData.map((item: CampaignTypeData) => Object.keys(item) as (keyof CampaignTypeData)[]);
    const uniquekeys = [...new Set(allkeys.flat())];
    let sortedUniqueKeys = uniquekeys.sort((a, b) => {
      return metricSortOrder.indexOf(a) - metricSortOrder.indexOf(b);
    });

    if (sortedUniqueKeys.includes('campaigntype')) {
      sortedUniqueKeys.splice(sortedUniqueKeys.indexOf('campaigntype'), 1);
    }

    const tdata = sortedUniqueKeys.map((key) => {
      return {
        id: key as keyof Omit<CampaignTypeData, 'campaigntype'>,
        label: key as keyof Omit<CampaignTypeData, 'campaigntype'>,
        ...chartData.reduce((acc: BarChartData, item: CampaignTypeData) => {
          acc[item.campaigntype as CampaignType] = item[key] as number;
          return acc;
        }, {} as BarChartData),
      };
    });

    return tdata;
  }, [chartData.length, isLoading]);

  /**
   * Compare should be disabled only until it is fully built.
   * There's no other reason for it to be disabled.
   */
  useEffect(() => {
    setCompareEnabled(false);
    return () => {
      setCompareEnabled(true);
    };
  }, []);

  const tooLittleData = chartData?.length === 0;

  /**
   *
   * Do not display search volume/interest here. Search volume/interest is calculated as a trend and not fitting for a bar chart.
   */
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData ? (
        <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
          <AlertTitle>Info</AlertTitle>
          There's not enough data. Please select a different date range.
        </Alert>
      ) : (
        <Grid container spacing={2}>
          {superData.map((data) => {
            const helpTextKey =
              data?.label?.toUpperCase() === 'RETURNONEXPENDITURETYPE'
                ? portalUser.return_on_expenditure_type.toUpperCase()
                : (data?.label?.toUpperCase() as keyof HelpTextType);
            return (
              data?.label !== 'searchvolume' && (
                <Grid item xs={12} md={6} lg={4} sx={{ maxHeight: '320px' }}>
                  <div style={{ backgroundColor: BEIGE, padding: '12px' }}>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="end">
                      <h4>
                        {humanReadable.insightsKPIs[data?.label as keyof HumanReadableInsightsKPIsType](
                          portalUser
                        ).toUpperCase()}
                      </h4>
                      <HelpModal
                        helptext={helptexts[helpTextKey as keyof HelpTextType]?.content}
                        helpTextTitle={helptexts[helpTextKey as keyof HelpTextType]?.title}
                      />
                    </Stack>
                    <ErrorBoundary>
                      <div className="Chart__wrapper" ref={ref} style={{ height: '220px' }}>
                        <NivoBarChart chartData={[data]} />
                      </div>
                    </ErrorBoundary>
                  </div>
                </Grid>
              )
            );
          })}
        </Grid>
      )}
    </ErrorBoundary>
  );
};

export default CampaignTypeDataBarChartView;
