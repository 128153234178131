import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import React, { useMemo } from 'react';

import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';

import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { enumerateDaysBetweenDates } from 'Utils/Helpers';

import { AggregatedDataSerie, AggregatedDataType, PortalUser } from 'Types/insightstypes';

import { BasicTooltip } from '@nivo/tooltip';
import { BLUE, ORANGE } from 'Utils/colors';
import { humanReadable } from 'Utils/humanReadableTitles';
import * as d3 from 'd3';

type Props = { data: [AggregatedDataSerie, AggregatedDataSerie]; foregroundColor?: string[]; backgroundColor?: string };

function addIndexToDates(timeSeries: any) {
  return timeSeries?.map((dataPoint: any, index: number) => {
    const { x, y } = dataPoint;
    return { x, y, index };
  });
}

const CustomTooltip = (
  props: Omit<
    BarTooltipProps<[AggregatedDataSerie, AggregatedDataSerie]>,
    'hidden' | 'index' | 'indexValue' | 'data'
  > & { dataId: string; portalUser: PortalUser }
) => {
  const displaylabel = props.label.indexOf('-') > -1 ? props.label.slice(props.label.indexOf('-') + 1) : '';
  const displayvalueformatter =
    humanReadable.insightsKPIValueFormat[props.dataId as keyof AggregatedDataType](props.portalUser) ||
    props.formattedValue;
  const displayvalue = d3.format(displayvalueformatter)(props.value);
  return (
    <BasicTooltip
      id={
        <span>
          <strong>{displaylabel}</strong> : {displayvalue}
        </span>
      }
      enableChip={true}
      color={props.color}
    />
  );
};

const NivoBarDelta = ({ data, foregroundColor, backgroundColor }: Props) => {
  const { dateRange, alternateDateRange, portalUser } = useInsightsQuery();
  let datacopy = structuredClone(data);
  let [originalTimeSeries, comparisonTimeSeries] = datacopy;
  const dataId = originalTimeSeries?.id;

  const originalTimeSeriesWithGapsFilled: AggregatedDataSerie['data'] = useMemo(() => {
    const dates = enumerateDaysBetweenDates(dayjs(dateRange[0]), dayjs(dateRange[1]));
    const originalTimeSeriesWithGapsFilled = dates.map((date) => {
      const originalDataPoint = originalTimeSeries.data.find((dataPoint: any) => {
        return dayjs(dataPoint.x).isSame(date, 'day');
      });
      const x = date;
      const y = originalDataPoint?.y;
      return { x, y };
    });

    return originalTimeSeriesWithGapsFilled;
  }, [originalTimeSeries?.data?.length, dateRange[0], dateRange[1]]);

  const comparisonTimeSeriesWithGapsFilled: AggregatedDataSerie['data'] = useMemo(() => {
    const dates = enumerateDaysBetweenDates(dayjs(alternateDateRange[0]), dayjs(alternateDateRange[1]));
    const comparisonTimeSeriesWithGapsFilled = dates.map((date) => {
      const comparisonDataPoint = comparisonTimeSeries.data.find((dataPoint: any) => {
        return dayjs(dataPoint.x).isSame(date, 'day');
      });
      const x = date;
      const y = comparisonDataPoint?.y;
      return { x, y };
    });

    return comparisonTimeSeriesWithGapsFilled;
  }, [comparisonTimeSeries?.data?.length, alternateDateRange[0], alternateDateRange[1]]);

  const originalTimeSeriesWithDayNumbers: AggregatedDataSerie['data'] = addIndexToDates(
    originalTimeSeriesWithGapsFilled
  );
  const comparisonTimeSeriesWithDayNumbers: AggregatedDataSerie['data'] = addIndexToDates(
    comparisonTimeSeriesWithGapsFilled
  );
  originalTimeSeries.data = originalTimeSeriesWithDayNumbers;
  comparisonTimeSeries.data = comparisonTimeSeriesWithDayNumbers;

  const deltaData = useMemo(() => {
    const deltaData = originalTimeSeriesWithDayNumbers?.map((dataPoint: any) => {
      const { x, y, index } = dataPoint;
      if (y === null) {
        return { x, y: null };
      }
      const comparisonDataPoint = comparisonTimeSeriesWithDayNumbers[index];
      const comparisonY = comparisonDataPoint?.y as number;
      if (comparisonY === null) {
        return { x, y: null };
      }

      const delta = y - comparisonY;
      const change = 0 + delta;
      const result =
        change > 0 ? { pos: Number.isNaN(change) ? null : change } : { neg: Number.isNaN(change) ? null : change };

      return { x, ...result };
    });

    const deltaDataPositives = deltaData.map((dataPoint: any) => {
      const { x, y } = dataPoint;
      const positiveY = y > 0 ? y : null;
      return { x, y: positiveY };
    });

    const deltaDataNegatives = deltaData.map((dataPoint: any) => {
      const { x, y } = dataPoint;
      const negativeY = y < 0 ? y : null;
      return { x, y: negativeY };
    });

    return [deltaData, deltaDataPositives, deltaDataNegatives];
  }, [originalTimeSeries?.data?.length, comparisonTimeSeriesWithGapsFilled?.length]);

  const deltaLine = structuredClone(originalTimeSeries);
  deltaLine.id = 'delta';
  deltaLine.sortKey = 'delta';
  deltaLine.data = deltaData[0];

  const deltaLinePositives = structuredClone(originalTimeSeries);
  deltaLinePositives.id = 'deltaPositives';
  deltaLinePositives.sortKey = 'deltaPositives';
  deltaLinePositives.data = deltaData[1];
  const max = d3.max(deltaLinePositives?.data, (d) => (d.y ? d.y : 0) as number | null);

  const deltaLineNegatives = structuredClone(originalTimeSeries);
  deltaLineNegatives.id = 'deltaNegatives';
  deltaLineNegatives.sortKey = 'deltaNegatives';
  deltaLineNegatives.data = deltaData[2];
  const min = d3.min(deltaLineNegatives?.data, (d) => (d.y ? d.y : 0) as number | null);
  const hasValues = (originalTimeSeries?.data?.length > 0 || comparisonTimeSeries?.data?.length > 0) && max && min;
  const keys = ['pos', 'neg'];
  const colors = [BLUE, ORANGE];
  const domain = d3.extent(deltaData[0], (d) => dayjs(d.x).format('YYYY-MM-DD'));
  const center = Math.floor(deltaData[0]?.length / 2);
  const centerDate = dayjs(deltaData[0][center].x).format('YYYY-MM-DD');
  const tickValues = [domain[0], centerDate, domain[1]];

  /**
   * The enclosing div should be unnecessary because the Chart Wrapper has a height. Dont know why it is needed.
   */

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ResponsiveBar
        data={deltaData[0]}
        keys={keys}
        indexBy="x"
        margin={{ top: 20, right: 40, bottom: 90, left: 50 }}
        colors={colors}
        padding={0.3}
        layout="vertical"
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={false}
        enableGridY={false}
        axisBottom={{
          tickValues: tickValues,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        markers={[
          {
            axis: 'y',
            legend: '+/-0',
            legendPosition: 'bottom-left',
            lineStyle: {
              stroke: 'black',
              strokeWidth: 1,
            },
            value: 0,
          },
        ]}
        layers={['grid', 'axes', 'bars', 'markers', 'legends']}
        legends={[
          {
            dataFrom: 'keys',
            data: keys.map((id, index) => {
              return {
                color: colors[index],
                id,
                label: id === 'pos' ? 'Current > Comparison' : 'Comparison > Current',
              };
            }),
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 50,
            itemsSpacing: 20,
            itemDirection: 'top-to-bottom',
            itemWidth: 110,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'square',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        tooltip={(node) => (
          <CustomTooltip
            color={node.color}
            id={node.id}
            label={node.label}
            value={node.value}
            formattedValue={node.formattedValue}
            dataId={dataId as string}
            portalUser={portalUser}
          />
        )}
      />
    </div>
  );
};

export default NivoBarDelta;