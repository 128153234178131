import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import React, { createContext, useContext, useState, useCallback } from 'react';

type MuiNotificationConfig = {
  open?: boolean;
  message: string;
  severity?: 'success' | 'info' | 'warning' | 'error';
};

type MuiNotificationContextType = {
  notify: React.Dispatch<React.SetStateAction<MuiNotificationConfig>>;
};

type TransitionProps = Omit<SlideProps, 'direction'>;

const MuiNotificationContext = createContext<MuiNotificationContextType | null>(null);

export function useMuiNotification() {
  return useContext(MuiNotificationContext);
}

export function MuiNotificationProvider({ children }: { children: React.ReactNode }) {
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const value = {
    notify: useCallback(
      (settings: MuiNotificationConfig) => setNotification((prev) => ({ ...prev, ...settings, open: true })),
      []
    ),
  };

  const handleClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <MuiNotificationContext.Provider value={value}>
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
      >
        <Alert severity={notification.severity as AlertColor} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </MuiNotificationContext.Provider>
  );
}
