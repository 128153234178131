import { ResizeObserver } from '@juggle/resize-observer';
import { useRef, useState, useEffect } from 'react';

type chartSettings = {
  width?: number;
  height?: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
};

const combineChartDimensions = (dimensions: chartSettings) => {
  const parsedDimensions = {
    ...dimensions,
    marginTop: dimensions.marginTop || 10,
    marginRight: dimensions.marginRight || 10,
    marginBottom: dimensions.marginBottom || 40,
    marginLeft: dimensions.marginLeft || 75,
  };
  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  };
};

const useChartDimensions = (
  passedSettings: chartSettings
): [React.MutableRefObject<HTMLDivElement>, ReturnType<typeof combineChartDimensions>] => {
  const ref = useRef<HTMLDivElement>();
  const dimensions = combineChartDimensions(passedSettings);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (dimensions.width && dimensions.height) return;
    if (ref.current === undefined) return;

    const element = ref.current;
    if (element === null) return;

    /**
     * @juggle/resize-observer is a polyfill for the ResizeObserver API.
     * https://github.com/que-etc/resize-observer-polyfill/issues/35
     * Typescript is telling you that it does not know that the imported ResizeObserver can be instantiated like a class,
     * because no type definitions are provided with this module.
     * You can overcome this by explicitly declaring the type as any like this:
     */
    const _ResizeObserver: any = ResizeObserver;
    const resizeObserver = new _ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;

      const entry = entries[0];

      if (width != entry.contentRect.width) setWidth(entry.contentRect.width);
      if (height != entry.contentRect.height) setHeight(entry.contentRect.height);
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [ref.current]);

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  });

  return [ref, newSettings];
};

export default useChartDimensions;
