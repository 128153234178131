import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DateSelector from '../DateSelector';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function DateRangeDialog({ open, onClose }: Props) {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Select a Custom Date Range</DialogTitle>
      <DialogContent sx={{ paddingTop: '12px' }}>
        <DateSelector />
      </DialogContent>
    </Dialog>
  );
}
