import Grid from '@mui/material/Grid';
import helptexts, { HelpTextType } from '../../Assets/JSON/helptexts';
import { useInsightsQuery } from '../../Context/InsightsQueryContext';
import useChartDimensions from '../../Hooks/useChartDimensions';
import useComparisonData from '../../Hooks/useComparisonData';
import { BingCampaignDataType, BingCampaignDataByDateType } from '../../Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { HumanReadableInsightsKPIsType, humanReadable } from '../../Utils/humanReadableTitles';
import * as d3 from 'd3';
import React, { Suspense } from 'react';
import { BEIGE, PINK, TEAL, YELLOW, BLACK, ORANGE, BLUE } from '../../Utils/colors';
import ChartSwitchWrapper from './Components/ChartSwitchWrapper';
import ErrorBoundary from './Components/ErrorBoundary';
import NivoLineChart from './Components/NivoLineChart';
import NivoMultipleLineChart from './Components/NivoMultipleLineChart/NivoMultipleLineChart';
import ChartHeader from './Components/ChartHeader';
import NivoBarDelta from './Components/NivoBarDelta';

type Props = {
  dataByDay: BingCampaignDataByDateType[];
  comparisondata: BingCampaignDataByDateType[];
  dataTotal: BingCampaignDataType;
  comparisonTotal: BingCampaignDataType;
};
interface Datum {
  x?: Date | number | string;
  y?: Date | number | string;
  [key: string]: any;
}

interface Serie {
  id: string | number;
  sortKey?: string | number;
  data: Datum[];
  [key: string]: any;
}

function dataGetter(
  accumulator: { [key: string]: Serie },
  currentValue: BingCampaignDataByDateType,
  currentIndex: number,
  array: BingCampaignDataByDateType[],
  isOriginal: boolean
) {
  const keys = Object.keys(currentValue).sort((a, b) => {
    return (metricSortOrder.indexOf(a.toLowerCase()) || 1) - (metricSortOrder.indexOf(b.toLowerCase()) || -1);
  });
  keys.forEach((key) => {
    const accumulatorKey = key as keyof BingCampaignDataByDateType;
    if (accumulatorKey === 'searchimpressionshare' || accumulatorKey === 'impressionshare') return accumulator;
    if (key !== 'reportdate') {
      const id = isOriginal ? key : `${key}_comparison`;
      if (!accumulator[accumulatorKey]) {
        accumulator[accumulatorKey] = { id, label: key, sortKey: key, data: [] };
      }
      accumulator[accumulatorKey].id = id;
      accumulator[accumulatorKey].label = key;
      accumulator[accumulatorKey].sortKey = key;
      accumulator[accumulatorKey].data = [
        ...accumulator[accumulatorKey].data,
        { x: dayjs(currentValue.reportdate).format('YYYY-MM-DD'), y: currentValue[accumulatorKey] },
      ];
    }
  });

  return accumulator;
}

type DataPerCategoryType = {
  [key: string]: Serie;
};

const chartSettings = {
  marginTop: 20,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 10,
};

const metricSortOrder = [
  'roas',
  'returnonexpenditurevalue',
  'cpa',
  'cost',
  'searchvolume',
  'conversionvalue',
  'convrate',
  'conversionrate',
  'clicks',
  'impressions',
  'conversions',
  'searchimpressionshare',
  'impressionshare',
  'ctr',
  'clickthroughrate',
  'frequency',
  'landingpageviews',
  'newusers',
  'returningusers',
  'uniqueusers',
];
function BingCampaignDataMultiView({ dataByDay, dataTotal, comparisondata, comparisonTotal }: Props) {
  const { portalUser, shallCompare, queryparams } = useInsightsQuery();
  const [ref, dms] = useChartDimensions(chartSettings);

  const [originalData, comparisonData, ...errors]: [DataPerCategoryType, DataPerCategoryType, ...string[]] =
    useComparisonData([dataByDay, comparisondata, dataGetter, queryparams]);

  return (
    <Grid container spacing={2}>
      {originalData &&
        Object.keys(originalData).map((key) => {
          const data = originalData[key as keyof BingCampaignDataType];
          const comparisondata = comparisonData[key as keyof BingCampaignDataType];

          const formatString =
            humanReadable.insightsKPIValueFormat[data?.sortKey as keyof HumanReadableInsightsKPIsType](portalUser);

          const metricValue = dataTotal?.[data?.sortKey as keyof BingCampaignDataType];
          const metricComparisonValue = shallCompare
            ? comparisonTotal[data?.sortKey as keyof BingCampaignDataType]
            : null;

          const formattedMetricValue = d3.format(formatString)(metricValue);
          const formattedMetricComparisonValue = shallCompare ? d3.format(formatString)(metricComparisonValue) : null;

          const helpTextKey =
            data?.label?.toUpperCase() === 'RETURNONEXPENDITUREVALUE'
              ? portalUser.return_on_expenditure_type.toUpperCase()
              : (data?.label?.toUpperCase() as keyof HelpTextType);

          const helpTextTitle = helptexts[helpTextKey as keyof HelpTextType]?.title;
          const helpText = helptexts[helpTextKey as keyof HelpTextType]?.content;
          const label =
            typeof data?.label === 'string'
              ? humanReadable.insightsKPIs[data?.label as keyof HumanReadableInsightsKPIsType](portalUser).toUpperCase()
              : data?.label;
          return (
            <Suspense fallback={<div>Loading...</div>} key={data?.id}>
              {data?.label === 'searchvolume' ? (
                <Grid item xs={12} sx={{ maxHeight: '380px' }}>
                  <div style={{ backgroundColor: BEIGE, padding: '12px' }}>
                    <ErrorBoundary>
                      <ChartHeader
                        label="Search Interest"
                        key={data?.sortKey}
                        sortKey={data?.sortKey}
                        helpText={helptexts.SEARCHINTEREST.content}
                        helpTextTitle={helptexts.SEARCHINTEREST.title}
                        value={formattedMetricValue}
                        comparisonValue={formattedMetricComparisonValue}
                        metricValue={metricValue}
                        metricComparisonValue={metricComparisonValue}
                      />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      {shallCompare && comparisondata ? (
                        <ChartSwitchWrapper>
                          {(showDelta) =>
                            !showDelta ? (
                              <NivoMultipleLineChart
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            ) : (
                              <NivoBarDelta
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            )
                          }
                        </ChartSwitchWrapper>
                      ) : (
                        <div className="Chart__wrapper" ref={ref} style={{ height: '240px', marginTop: '22px' }}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <NivoLineChart
                              key={data?.label}
                              data={data}
                              hasTrendLine
                              foregroundColor={[ORANGE, BLUE]}
                              backgroundColor={TEAL}
                            />
                          </Suspense>
                        </div>
                      )}
                    </ErrorBoundary>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={4} sx={{ maxHeight: '380px' }}>
                  <div style={{ backgroundColor: BEIGE, padding: '12px' }}>
                    <ErrorBoundary>
                      <ChartHeader
                        label={label}
                        key={data?.sortKey}
                        sortKey={data?.sortKey}
                        helpText={helpText}
                        helpTextTitle={helpTextTitle}
                        value={formattedMetricValue}
                        comparisonValue={formattedMetricComparisonValue}
                        metricValue={metricValue}
                        metricComparisonValue={metricComparisonValue}
                      />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      {shallCompare && comparisondata ? (
                        <ChartSwitchWrapper>
                          {(showDelta) =>
                            !showDelta ? (
                              <NivoMultipleLineChart data={[data, comparisondata]} />
                            ) : (
                              <NivoBarDelta
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            )
                          }
                        </ChartSwitchWrapper>
                      ) : (
                        <div className="Chart__wrapper" ref={ref} style={{ height: '240px', marginTop: '22px' }}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <NivoLineChart key={data?.label} data={data} hasTrendLine />
                          </Suspense>
                        </div>
                      )}
                    </ErrorBoundary>
                  </div>
                </Grid>
              )}
            </Suspense>
          );
        })}
    </Grid>
  );
}

export default BingCampaignDataMultiView;
