import * as React from 'react';

export interface PortalStats {
  organizationid: number;
  data?: {
    info: { [key: string]: string };
    sum: {
      [key: string]: number;
      // conversionvalue: number
      // conversions: number
      // cost: number
      // clicks: number
      // returnonexpenditurevalue: number
      // noofchanges: number
      // impressions: number
      // clickthroughrate: number
    };
    dates: { [key: string]: number | string }[];
    availablefilters: {};
  };
}

const addToPortalStatsList = (portalstatslist: PortalStats[], portalstats: PortalStats) =>
  portalstatslist.some((stat) => stat.organizationid === portalstats.organizationid)
    ? [...portalstatslist.filter((stat) => stat.organizationid !== portalstats.organizationid), portalstats]
    : [...portalstatslist, portalstats];

const getPortalStatForOrganization = (portalstatslist: PortalStats[], organizationid: number): PortalStats =>
  portalstatslist.filter((stat) => stat.organizationid === organizationid).pop();

export const usePortalStats = (initial: PortalStats[] = []) => {
  const [portalStatsList, setPortalStatsList] = React.useState<PortalStats[]>(initial);
  return {
    portalstats: portalStatsList,
    load: (portalStats: PortalStats) => setPortalStatsList((previous) => addToPortalStatsList(previous, portalStats)),
    getportalstats: (organizationid: number): PortalStats =>
      getPortalStatForOrganization(portalStatsList, organizationid),
  };
};

export type UsePortalStatsType = ReturnType<typeof usePortalStats>;

export interface PartnerMessage {
  id: number;
  message: string;
  active: boolean;
  time_stamp: Date;
}

// Custom hook for managing Amanda's partner messages
export const usePartnerMessages = (initial: PartnerMessage[] = []) => {
  const [partnerMessages, setPartnerMessages] = React.useState<PartnerMessage[]>(initial);
  const [newPartnerMessage] = React.useState<PartnerMessage>(Object);

  return {
    partnerMessages,
    newPartnerMessage,
    load: (message: PartnerMessage[]) => setPartnerMessages(message),
  };
};

export type UsePartnerMessagesType = ReturnType<typeof usePartnerMessages>;

export interface IPartnerUser {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export const usePartnerUser = (initial: IPartnerUser[] = []) => {
  const [partnerUsers, setPartnerUsers] = React.useState<IPartnerUser[]>(initial);
  const [newPartnerUser] = React.useState<IPartnerUser[]>(initial);

  return {
    partnerusers: partnerUsers,
    newpartneruser: newPartnerUser,
    load: (newPartnerUsers: IPartnerUser[]) => setPartnerUsers(newPartnerUsers),
  };
};

export interface Organization {
  id: number;
  name: string;
  return_on_expenditure_type: string;
  domains: string[];
  stores?: number[];
  audit: Record<string, unknown>;
  account_manager?: object | null;
}

const addOrganization = (organizationslist: Organization[], organization: Organization) => [
  ...organizationslist,
  organization,
];

// Add Amanda Store to organization
const addOrganizationStore = (organizationslist: Organization[], organization: Organization, store: number) =>
  organizationslist.map((org) => ({
    ...org,
    stores: org.id === organization.id ? [...(org.stores ?? []), store] : [...(org.stores ?? [])],
  }));

// Custom hook for managing Amanda's partner organizations
export const useOrganizations = (initial: Organization[] = []) => {
  const [organizations, setOrganizations] = React.useState<Organization[]>(initial);
  const [newOrganization, setNewOrganization] = React.useState<Organization>(Object);
  const [isLoading, setIsLoading] = React.useState(false);

  return {
    isLoading,
    setIsLoading,
    organizations,
    newOrganization,
    setNewOrganization,
    load: (newOrganizations: Organization[]) => setOrganizations(newOrganizations),
    addOrganizationStore: (org: Organization, store: number) =>
      setOrganizations((prev) => addOrganizationStore(prev, org, store)),
    addOrganization: React.useCallback(
      (org: Organization) => setOrganizations((prev) => addOrganization(prev, org)),
      [newOrganization]
    ),
  };
};

export type UseOrganizationsType = ReturnType<typeof useOrganizations>;

export interface CampaignStore {
  id: number;
  name: string;
  platform: string;
  allowedit: boolean;
  marketingcountry: string[];
  amandaenabled: boolean;
  budgetallocatoractive: boolean;
  budgetstrategyactive: boolean;
  budget: number;
  budgetfrequency: 'M' | 'D';
  budgetcurrency: string;
}

const addCampaignStore = (campaignstorelist: CampaignStore[], campaignstore: CampaignStore) => [
  ...campaignstorelist,
  campaignstore,
];

// Custom hook for managing Amanda's partner campaign stores
export const useCampaignStores = (initial: CampaignStore[] = []) => {
  const [campaignStores, setCampaignStores] = React.useState<CampaignStore[]>(initial);
  const [newCampaignStore] = React.useState<CampaignStore>(Object);

  return {
    campaignStores,
    newCampaignStore,
    addCampaignStore: React.useCallback(
      (store: CampaignStore) => setCampaignStores((prev) => addCampaignStore(prev, store)),
      [newCampaignStore]
    ),
    load: (newCampaignStores: CampaignStore[]) => setCampaignStores(newCampaignStores),
  };
};

export type UseCampaignStoresType = ReturnType<typeof useCampaignStores>;
