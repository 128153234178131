import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { PortalUser } from 'Types/insightstypes';
import { InsightsRequest } from 'Types/insightstypes';
export type DateSelectorConfig = [Date | null, Date | null];

type InsightsQueryContextType = {
  dateRange: DateSelectorConfig;
  setDateRange: React.Dispatch<React.SetStateAction<DateSelectorConfig>>;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  startDateLastYear: string;
  endDateLastYear: string;
  startDate: string;
  endDate: string;
  periodLength: number;
  platforms: PlatformType[];
  platformsEnabled: boolean;
  setPlatformsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setPlatforms: React.Dispatch<React.SetStateAction<PlatformType[]>>;
  portalUser: PortalUser;
  setPortalUser: React.Dispatch<React.SetStateAction<PortalUser>>;
  countries: string[];
  setCountries: React.Dispatch<React.SetStateAction<string[]>>;
  queryparams: InsightsRequest;
  includeBrandCampaigns: boolean;
  setIncludeBrandCampaigns: React.Dispatch<React.SetStateAction<boolean>>;
  isReadyToRun: boolean;
  setAlternateStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setAlternateEndDate: React.Dispatch<React.SetStateAction<Date>>;
  alternateStartDate: Date;
  alternateEndDate: Date;
  alternateDateRange: DateSelectorConfig;
  setAlternateDateRange: React.Dispatch<React.SetStateAction<DateSelectorConfig>>;
  shallCompare: boolean;
  setShallCompare: React.Dispatch<React.SetStateAction<boolean>>;
  compareEnabled: boolean;
  setCompareEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type PlatformType = string;

const InsightsQueryContext = createContext<InsightsQueryContextType | null>(null);

export function useInsightsQuery() {
  return useContext(InsightsQueryContext);
}

export function InsightsQueryProvider({ children }: { children: React.ReactNode }) {
  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().subtract(1, 'day').toDate());
  const [alternateStartDate, setAlternateStartDate] = useState<Date>(dayjs().subtract(2, 'month').toDate());
  const [alternateEndDate, setAlternateEndDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
  const [shallCompare, setShallCompare] = useState<boolean>(false);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(true);
  const [platforms, setPlatforms] = useState<PlatformType[]>(['google', 'bing', 'meta']);
  const [platformsEnabled, setPlatformsEnabled] = useState<boolean>(true);
  const [portalUser, setPortalUser] = useState<PortalUser | null>(null);
  const [countries, setCountries] = useState([]);
  const [includeBrandCampaigns, setIncludeBrandCampaigns] = useState(true);
  const dateRange: DateSelectorConfig = [startDate, endDate];
  const alternateDateRange: DateSelectorConfig = [alternateStartDate, alternateEndDate];
  const queryparams: InsightsRequest = {
    startdate: dayjs(startDate).format('YYYY-MM-DD'),
    enddate: dayjs(endDate).format('YYYY-MM-DD'),
    channels: platforms,
    portaluser: portalUser?.id,
    countries: countries,
    reports: [{ includebrandcampaigns: includeBrandCampaigns }],
  };

  useEffect(() => {
    setCountries([]);
  }, [portalUser]);

  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(dayjs(startDate).add(14, 'day').toDate());
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate < startDate) {
      setStartDate(dayjs(endDate).subtract(14, 'day').toDate());
    }
  }, [endDate]);

  const value = {
    dateRange,
    setDateRange: useCallback((dateRange: DateSelectorConfig) => {
      if (!Array.isArray(dateRange)) {
        throw new Error('Date range must be an array');
      }
      if (dateRange.length !== 2) {
        throw new Error('Date range must have two elements');
      }
      if (!(dateRange[0] instanceof Date)) {
        throw new Error('First element of date range must be a date');
      }
      if (!(dateRange[1] instanceof Date)) {
        throw new Error('Second element of date range must be a date');
      }
      const [startDate, endDate] = dateRange;
      setStartDate(dayjs(startDate).toDate());
      setEndDate(dayjs(endDate).toDate());
    }, []),
    setStartDate: useCallback((date: Date | null) => {
      setStartDate(date);
    }, []),
    setEndDate: useCallback((date: Date | null) => {
      setEndDate(date);
    }, []),
    startDate: dayjs(startDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    startDateLastYear: dayjs(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
    endDateLastYear: dayjs(endDate).subtract(1, 'year').format('YYYY-MM-DD'),
    alternateDateRange,
    alternateStartDate: dayjs(alternateStartDate).toDate(),
    alternateEndDate: dayjs(alternateEndDate).toDate(),
    setAlternateStartDate: useCallback((date: Date | null) => {
      setAlternateStartDate(date);
    }, []),
    setAlternateEndDate: useCallback((date: Date | null) => {
      setAlternateEndDate(date);
    }, []),
    setAlternateDateRange: useCallback((dateRange: DateSelectorConfig) => {
      if (!Array.isArray(dateRange)) {
        throw new Error('Date range must be an array');
      }
      if (dateRange.length !== 2) {
        throw new Error('Date range must have two elements');
      }
      if (!(dateRange[0] instanceof Date)) {
        throw new Error('First element of date range must be a date');
      }
      if (!(dateRange[1] instanceof Date)) {
        throw new Error('Second element of date range must be a date');
      }
      const [startDate, endDate] = dateRange;
      setAlternateStartDate(dayjs(startDate).toDate());
      setAlternateEndDate(dayjs(endDate).toDate());
    }, []),
    periodLength: dayjs(endDate).diff(dayjs(startDate), 'day') + 1, // +1 because diff is exclusive
    platforms,
    setPlatforms,
    platformsEnabled,
    setPlatformsEnabled,
    portalUser,
    setPortalUser,
    queryparams,
    countries,
    setCountries: useCallback((countrycodes: string[]) => setCountries(countrycodes), []),
    includeBrandCampaigns,
    setIncludeBrandCampaigns,
    isReadyToRun: !!(startDate && endDate && portalUser && countries.length > 0 && platforms.length > 0),
    shallCompare,
    setShallCompare,
    compareEnabled,
    setCompareEnabled,
  };

  return <InsightsQueryContext.Provider value={value}>{children}</InsightsQueryContext.Provider>;
}

export default InsightsQueryProvider;
