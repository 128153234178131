import { captureException } from '@sentry/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import useLocalStorage from '../Hooks/useLocalStorage.ts';

export const AuthContext = React.createContext({ loggedin: false });

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoggedin, setIsLoggedIn] = useState(false);
  const [isCheckingLogin, setIsCheckingLogin] = useState(false);
  const [activeClient, setActiveClient] = useLocalStorage('activeClient', null);

  const checkLogin = async () => axios.get('/api/loggedin');

  const signOut = () => {
    setUser(null);
    setActiveClient('');
    axios
      .get('/logout')
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch(() => setIsLoggedIn(false));
  };

  const activateUser = (activeuser) => {
    try {
      if (activeuser?.category === 'STAFF') {
        if (activeClient) return activeuser;
        setActiveClient('INHOUSEPORTAL');
        return activeuser;
      }

      throw ReferenceError();
    } catch (error) {
      captureException(error);
    }
    return activeuser;
  };

  const selectClient = (clientname) => {
    /**
     * Make sure chosen portal applications is among
     * the active users available choices.
     */
    // eslint-disable-next-line camelcase
    const { app_access_choices: appchoices } = user;
    const appnames = appchoices.map((app) => app.name);
    if (appnames.indexOf(clientname) >= 0) return clientname;
    return undefined;
  };

  const login = useMemo(
    () => ({
      user,
      setUser: (activeuser) => setUser(() => activateUser(activeuser)),
      loggedin: isLoggedin,
      setisloggedin: setIsLoggedIn,
      signOut,
      isCheckingLogin,
      checkLogin,
      activeclient: activeClient,
      setactiveclient: (clientname) => setActiveClient(() => selectClient(clientname)),
    }),
    [user, user?.category, user?.uuid, activeClient, isCheckingLogin, activeClient]
  );

  useEffect(() => {
    setIsCheckingLogin(true);
    checkLogin()
      .then((res) => {
        setUser(res.data?.user);
        setIsLoggedIn(true);
        setIsCheckingLogin(false);
      })
      .catch(() => {
        setIsLoggedIn(false);
        setIsCheckingLogin(false);
      });
  }, []);

  return <AuthContext.Provider value={login}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useAuth = () => React.useContext(AuthContext);
