/* eslint-disable react/forbid-prop-types */
import React, { lazy, Suspense, useEffect } from 'react';
import { Await, Navigate, useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { MuiNotificationProvider } from 'Context/MuiNotificiationContext';
import InsightsPortalPlayground from 'Pages/Insights/InsightsPortal';
import AppControls from '../AppNavigation/AppControls';
import AppSwitcher from '../AppNavigation/AppSwitcher';
import ParentSwitcher from '../AppNavigation/ParentSwitcher';
import Loading from '../Loading';
import { useAuth } from '../../Context/AuthContext';
import IdleHandler from '../IdleHandler/IdleHandler';
import StateLoader from '../StateLoader/StateLoader';

const InHousePortalApp = lazy(() => import('../../InHousePortalApp'));
const PartnerApp = lazy(() => import('../../Pages/PartnerPortal/App.tsx'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#006fff',
    },
    secondary: {
      main: '#fc5607',
    },
    pink: {
      main: '#fe8cb5',
    },
  },
  typography: {
    fontFamily: [
      '"Suisse Intl"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function Dispatcher() {
  const { user, activeclient } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [activeclient]);

  /**
   * Component for navigating to old client portal for client login.
   * Should be temporary and replaced by insights portal ASAP.
   *
   * @component
   * @example
   * const user.category = "CLIENT"
   * return (
   *   window.location.replace('/data');
   * )
   */
  if (user.category === 'CLIENT') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <ErrorBoundary>
          <div style={{ height: '100vh' }}>
            <InsightsPortalPlayground />
          </div>
        </ErrorBoundary>
      </Suspense>
    );
  }

  /**
   * Component for rendering in house portal for STAFF login
   * with the in house portal as last selected default.
   *
   * @component
   * @example
   * const user.category = 'STAFF'
   * const activeclient = 'INHOUSEPORTAL'
   * return (
   *    <Suspense fallback={<Loading />}>
   *    <Await errorElement="Error">
   *       {() => (
   *         <>
   *           <AppControls>
   *             <AppSwitcher />
   *           </AppControls>
   *           <InHousePortalApp user={user} />
   *         </>
   *       )}
   *     </Await>
   *   </Suspense>
   * )
   */
  if (user?.category === 'STAFF' && activeclient === 'INHOUSEPORTAL') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <ErrorBoundary>
          <AppControls>
            <AppSwitcher />
          </AppControls>
          <MuiNotificationProvider>
            <InHousePortalApp user={user} />
          </MuiNotificationProvider>
        </ErrorBoundary>
      </Suspense>
    );
  }

  /**
   * Component for rendering in house portal for STAFF login
   * with the partner portal as last selected default.
   *
   * @component
   * @example
   * const user.category = 'STAFF'
   * const activeclient = 'PARTNERPORTAL'
   * return (
   *   <Suspense fallback={<Loading />}>
   *      <Await errorElement="Error">
   *        {() => (
   *          <StateLoader>
   *            {(organizations) => (
   *              <ThemeProvider theme={theme}>
   *                <AppControls>
   *                  <ParentSwitcher organizations={organizations} />
   *                  <AppSwitcher />
   *                </AppControls>
   *                <PartnerApp user={user} organizations={organizations} />
   *              </ThemeProvider>
   *            )}
   *          </StateLoader>
   *        )}
   *      </Await>
   *    </Suspense>
   */
  if (user?.category === 'STAFF' && activeclient === 'PARTNERPORTAL') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <Await errorElement="Error">
          {() => (
            <StateLoader>
              {(descendantorganizations) => (
                <ThemeProvider theme={theme}>
                  <AppControls>
                    <ParentSwitcher descendantorganizations={descendantorganizations} />
                    <AppSwitcher />
                  </AppControls>
                  <MuiNotificationProvider>
                    <PartnerApp user={user} descendantorganizations={descendantorganizations} />
                  </MuiNotificationProvider>
                </ThemeProvider>
              )}
            </StateLoader>
          )}
        </Await>
      </Suspense>
    );
  }
  /**
   * Component for rendering in house portal for STAFF login
   * with the partner portal as last selected default.
   *
   * @component
   * @example
   * const user.category = 'STAFF'
   * const activeclient = 'CLIENTPORTAL'
   * return (
   *   <Suspense fallback={<Loading />}>
   *      <Await errorElement="Error">
   *        {() => (
   *          <StateLoader>
   *            {(organizations) => (
   *              <ThemeProvider theme={theme}>
   *                <AppControls>
   *                  <ParentSwitcher organizations={organizations} />
   *                  <AppSwitcher />
   *                </AppControls>
   *                <PartnerApp user={user} organizations={organizations} />
   *              </ThemeProvider>
   *            )}
   *          </StateLoader>
   *        )}
   *      </Await>
   *    </Suspense>
   */
  if (user?.category === 'STAFF' && activeclient === 'CLIENTPORTAL') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <ErrorBoundary>
          <AppControls>
            <AppSwitcher />
          </AppControls>
          <div style={{ height: '100vh' }}>
            <InsightsPortalPlayground />
          </div>
        </ErrorBoundary>
      </Suspense>
    );
  }

  /**
   * Component for rendering in house portal for PARTNER login
   * with no default.
   *
   * @component
   * @example
   * const user.category = 'PARTNER'
   * return (
   *   <Suspense fallback={<Loading />}>
   *      <Await errorElement="Error">
   *        {() => (
   *          <StateLoader>
   *            {(organizations) => (
   *              <ThemeProvider theme={theme}>
   *                <AppControls>
   *                  <ParentSwitcher organizations={organizations} />
   *                  <AppSwitcher />
   *                </AppControls>
   *                <PartnerApp user={user} organizations={organizations} />
   *              </ThemeProvider>
   *            )}
   *          </StateLoader>
   *        )}
   *      </Await>
   *    </Suspense>
   */
  if (user.category === 'PARTNER') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <Await errorElement="Error">
          {() => (
            <StateLoader>
              {(descendantorganizations) =>
                user ? (
                  <ThemeProvider theme={theme}>
                    <MuiNotificationProvider>
                      <PartnerApp user={user} descendantorganizations={descendantorganizations} />
                    </MuiNotificationProvider>
                  </ThemeProvider>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            </StateLoader>
          )}
        </Await>
      </Suspense>
    );
  }

  /**
   * Edge case navigates to login.
   *
   * @component
   * @example
   * return (
   *   <Navigate to="/" replace />
   * )
   */
  return <Navigate to="/" replace />;
}

export default Dispatcher;
