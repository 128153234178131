import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthProvider } from './Context/AuthContext';

import { LicenseInfo } from '@mui/x-license-pro';
import { LoadingProvider } from './Context/LoadingContext';

LicenseInfo.setLicenseKey(
  'e3cddf66a655e5a647fa409a29fd9136Tz00NDI3OCxFPTE2ODQ5MzgwMzAxNTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <LoadingProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LoadingProvider>
    </AuthProvider>
  </React.StrictMode>
);
