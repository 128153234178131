import React from 'react';
import './animation.css';
import { BEIGE, BLACK } from 'Utils/colors';

type Props = {
  width: number;
  foreground?: string;
  background?: string;
  isLoading?: boolean;
};

function AnimatedLogo({ width, foreground = BLACK, background = BEIGE, isLoading = false }: Props) {
  return (
    <div style={{ width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 550 400"
        preserveAspectRatio="xMidYMid meet"
        style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)' }}
      >
        <defs>
          <clipPath id="one">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              fillOpacity="1"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
            />
          </clipPath>
          <clipPath id="two">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              fillOpacity="1"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
            />
          </clipPath>
          <clipPath id="three">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              fillOpacity="1"
              d=" M0.34897398948669434,1.8159199953079224 C0.12193100154399872,1.361840009689331 0.4537639915943146,0.8378909826278687 0.9777100086212158,0.8378909826278687 C0.9777100086212158,0.8378909826278687 9.797459602355957,0.8378909826278687 9.797459602355957,0.8378909826278687 C11.299400329589844,0.8378909826278687 12.679200172424316,1.7111400365829468 13.307900428771973,3.0734000205993652 C13.307900428771973,3.0734000205993652 19.228500366210938,15.787799835205078 19.228500366210938,15.787799835205078 C19.438100814819336,16.241899490356445 19.106199264526367,16.76580047607422 18.59980010986328,16.76580047607422 C18.59980010986328,16.76580047607422 9.779999732971191,16.76580047607422 9.779999732971191,16.76580047607422 C8.278019905090332,16.76580047607422 6.8983001708984375,15.892600059509277 6.269559860229492,14.53030014038086 C6.269559860229492,14.53030014038086 0.34897398948669434,1.8159199953079224 0.34897398948669434,1.8159199953079224z"
            />
          </clipPath>
          <clipPath id="four">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              fillOpacity="1"
              d=" M13.406999588012695,3.145659923553467 C14.053199768066406,1.783400058746338 15.415399551391602,0.9101560115814209 16.917400360107422,0.9101560115814209 C16.917400360107422,0.9101560115814209 25.73710060119629,0.9101560115814209 25.73710060119629,0.9101560115814209 C26.243600845336914,0.9101560115814209 26.57550048828125,1.4341000318527222 26.36590003967285,1.8881900310516357 C26.36590003967285,1.8881900310516357 13.022700309753418,30.513099670410156 13.022700309753418,30.513099670410156 C12.394000053405762,31.87540054321289 11.014300346374512,32.748600006103516 9.512290000915527,32.748600006103516 C9.512290000915527,32.748600006103516 0.6925399899482727,32.748600006103516 0.6925399899482727,32.748600006103516 C0.18605899810791016,32.748600006103516 -0.14577199518680573,32.224700927734375 0.06380680203437805,31.770599365234375 C0.06380680203437805,31.770599365234375 13.406999588012695,3.145659923553467 13.406999588012695,3.145659923553467z"
            />
          </clipPath>

          <clipPath id="five">
            <path
              strokeLinecap="square"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="1"
              stroke="rgb(0,0,0)"
              strokeOpacity="0"
              strokeWidth="0"
              d=" M0.34897398948669434,1.8159199953079224 C0.12193100154399872,1.361840009689331 0.4537639915943146,0.8378909826278687 0.9777100086212158,0.8378909826278687 C0.9777100086212158,0.8378909826278687 9.797459602355957,0.8378909826278687 9.797459602355957,0.8378909826278687 C11.299400329589844,0.8378909826278687 12.679200172424316,1.7111400365829468 13.307900428771973,3.0734000205993652 C13.307900428771973,3.0734000205993652 19.228500366210938,15.787799835205078 19.228500366210938,15.787799835205078 C19.438100814819336,16.241899490356445 19.106199264526367,16.76580047607422 18.59980010986328,16.76580047607422 C18.59980010986328,16.76580047607422 9.779999732971191,16.76580047607422 9.779999732971191,16.76580047607422 C8.278019905090332,16.76580047607422 6.8983001708984375,15.892600059509277 6.269559860229492,14.53030014038086 C6.269559860229492,14.53030014038086 0.34897398948669434,1.8159199953079224 0.34897398948669434,1.8159199953079224z"
            />
          </clipPath>
        </defs>

        <g
          style={{ display: 'block' }}
          transform="matrix(7.497000217437744,0,0,7.497000217437744,287.53155517578125,19.597135543823242)"
          opacity="1"
        >
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              stroke={foreground}
              fill={background}
              fillOpacity="1"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
              clipPath="url(#one)"
            />
            <path
              strokeLinecap="square"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="1"
              stroke="rgb(0,0,0)"
              strokeOpacity="0"
              strokeWidth="0"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
            />
          </g>
        </g>
        <g
          style={{ display: 'block' }}
          transform="matrix(7.497000217437744,0,0,7.497000217437744,176.6649932861328,19.46799087524414)"
          opacity="1"
        >
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              stroke={foreground}
              fill={background}
              fillOpacity="1"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
              clipPath="url(#two)"
            />
            <path
              strokeLinecap="square"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="1"
              stroke="rgb(0,0,0)"
              strokeOpacity="0"
              strokeWidth="0"
              d=" M33.30739974975586,47.76639938354492 C33.30739974975586,47.76639938354492 24.487699508666992,47.76639938354492 24.487699508666992,47.76639938354492 C22.985700607299805,47.76639938354492 21.606000900268555,46.89310073852539 20.97719955444336,45.53089904785156 C20.97719955444336,45.53089904785156 0.19405600428581238,0.9780319929122925 0.19405600428581238,0.9780319929122925 C-0.015522800385951996,0.5239459872245789 0.3163110017776489,0 0.8227919936180115,0 C0.8227919936180115,0 9.642539978027344,0 9.642539978027344,0 C11.144499778747559,0 12.524200439453125,0.873242974281311 13.152999877929688,2.2355000972747803 C13.152999877929688,2.2355000972747803 33.93619918823242,46.78839874267578 33.93619918823242,46.78839874267578 C34.14569854736328,47.242401123046875 33.813899993896484,47.76639938354492 33.30739974975586,47.76639938354492z"
            ></path>
          </g>
        </g>
        <g
          style={{ display: 'block' }}
          transform="matrix(7.497000217437744,0,0,7.497000217437744,175.177001953125,252.08349609375)"
          opacity="1"
        >
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              stroke={foreground}
              fill={background}
              fillOpacity="1"
              d=" M0.34897398948669434,1.8159199953079224 C0.12193100154399872,1.361840009689331 0.4537639915943146,0.8378909826278687 0.9777100086212158,0.8378909826278687 C0.9777100086212158,0.8378909826278687 9.797459602355957,0.8378909826278687 9.797459602355957,0.8378909826278687 C11.299400329589844,0.8378909826278687 12.679200172424316,1.7111400365829468 13.307900428771973,3.0734000205993652 C13.307900428771973,3.0734000205993652 19.228500366210938,15.787799835205078 19.228500366210938,15.787799835205078 C19.438100814819336,16.241899490356445 19.106199264526367,16.76580047607422 18.59980010986328,16.76580047607422 C18.59980010986328,16.76580047607422 9.779999732971191,16.76580047607422 9.779999732971191,16.76580047607422 C8.278019905090332,16.76580047607422 6.8983001708984375,15.892600059509277 6.269559860229492,14.53030014038086 C6.269559860229492,14.53030014038086 0.34897398948669434,1.8159199953079224 0.34897398948669434,1.8159199953079224z"
              clipPath="url(#three)"
            />
            <path
              strokeLinecap="square"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="1"
              stroke="rgb(0,0,0)"
              strokeOpacity="0"
              strokeWidth="0"
              d=" M0.34897398948669434,1.8159199953079224 C0.12193100154399872,1.361840009689331 0.4537639915943146,0.8378909826278687 0.9777100086212158,0.8378909826278687 C0.9777100086212158,0.8378909826278687 9.797459602355957,0.8378909826278687 9.797459602355957,0.8378909826278687 C11.299400329589844,0.8378909826278687 12.679200172424316,1.7111400365829468 13.307900428771973,3.0734000205993652 C13.307900428771973,3.0734000205993652 19.228500366210938,15.787799835205078 19.228500366210938,15.787799835205078 C19.438100814819336,16.241899490356445 19.106199264526367,16.76580047607422 18.59980010986328,16.76580047607422 C18.59980010986328,16.76580047607422 9.779999732971191,16.76580047607422 9.779999732971191,16.76580047607422 C8.278019905090332,16.76580047607422 6.8983001708984375,15.892600059509277 6.269559860229492,14.53030014038086 C6.269559860229492,14.53030014038086 0.34897398948669434,1.8159199953079224 0.34897398948669434,1.8159199953079224z"
              clipPath="url(#five)"
            />
          </g>
        </g>
        <g
          style={{ display: 'block' }}
          transform="matrix(7.497000217437744,0,0,7.497000217437744,10.982497215270996,131.92300415039062)"
          opacity="1"
        >
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              className={'animatedLogo'}
              strokeWidth="1px"
              stroke={foreground}
              fill={background}
              fillOpacity="1"
              d=" M13.406999588012695,3.145659923553467 C14.053199768066406,1.783400058746338 15.415399551391602,0.9101560115814209 16.917400360107422,0.9101560115814209 C16.917400360107422,0.9101560115814209 25.73710060119629,0.9101560115814209 25.73710060119629,0.9101560115814209 C26.243600845336914,0.9101560115814209 26.57550048828125,1.4341000318527222 26.36590003967285,1.8881900310516357 C26.36590003967285,1.8881900310516357 13.022700309753418,30.513099670410156 13.022700309753418,30.513099670410156 C12.394000053405762,31.87540054321289 11.014300346374512,32.748600006103516 9.512290000915527,32.748600006103516 C9.512290000915527,32.748600006103516 0.6925399899482727,32.748600006103516 0.6925399899482727,32.748600006103516 C0.18605899810791016,32.748600006103516 -0.14577199518680573,32.224700927734375 0.06380680203437805,31.770599365234375 C0.06380680203437805,31.770599365234375 13.406999588012695,3.145659923553467 13.406999588012695,3.145659923553467z"
              clipPath="url(#four)"
            />
            <path
              strokeLinecap="square"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="1"
              stroke="rgb(0,0,0)"
              strokeOpacity="0"
              strokeWidth="0"
              d=" M13.406999588012695,3.145659923553467 C14.053199768066406,1.783400058746338 15.415399551391602,0.9101560115814209 16.917400360107422,0.9101560115814209 C16.917400360107422,0.9101560115814209 25.73710060119629,0.9101560115814209 25.73710060119629,0.9101560115814209 C26.243600845336914,0.9101560115814209 26.57550048828125,1.4341000318527222 26.36590003967285,1.8881900310516357 C26.36590003967285,1.8881900310516357 13.022700309753418,30.513099670410156 13.022700309753418,30.513099670410156 C12.394000053405762,31.87540054321289 11.014300346374512,32.748600006103516 9.512290000915527,32.748600006103516 C9.512290000915527,32.748600006103516 0.6925399899482727,32.748600006103516 0.6925399899482727,32.748600006103516 C0.18605899810791016,32.748600006103516 -0.14577199518680573,32.224700927734375 0.06380680203437805,31.770599365234375 C0.06380680203437805,31.770599365234375 13.406999588012695,3.145659923553467 13.406999588012695,3.145659923553467z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default AnimatedLogo;
