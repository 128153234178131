import { Backdrop, ButtonProps, Container } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { orange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import starsLoop from 'Assets/Video/stars-loop.mp4';
import { PINK } from 'Utils/colors';
import React, { useState, useEffect } from 'react';
import './splashscreen.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from 'Context/AuthContext';
import { useQuery } from 'react-query';
import { fetchUserLogins } from 'Services/logService';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  color: '#000',
  backgroundColor: '#fff',
  '&:hover': {
    color: '#fff',
    backgroundColor: orange[900],
  },
}));

type UserLoginLog = {
  timestamp: string;
};

const RELEASE_DATE = '2023-06-27';

const SplashScreen = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery('(max-width:600px)');

  const { data, isLoading }: { data: UserLoginLog[]; isLoading: boolean } = useQuery(
    'user',
    () => fetchUserLogins(user?.uuid),
    { enabled: !!user }
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const video = document.getElementById('space-background-video') as HTMLVideoElement;
    video.play();
  }, []);

  useEffect(() => {
    if (data) {
      const loginAfterReleaseDateCount = data.filter((log) =>
        dayjs(log?.timestamp).isSameOrAfter(dayjs(RELEASE_DATE))
      ).length;
      if (loginAfterReleaseDateCount < 10 && dayjs().isSameOrAfter(dayjs(RELEASE_DATE))) {
        setOpen(true);
      }
    }
  }, [data?.length]);

  return (
    <>
      <Backdrop
        sx={{ margin: '0 !important', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Container>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              maxHeight: '90vh',
              padding: '48px',
              paddingBottom: '72px',
            }}
          >
            <video autoPlay loop muted playsInline id="space-background-video">
              <source src={starsLoop} type="video/mp4" />
            </video>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 8, sm: 8, md: 2 }}>
              {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: matches ? 0 : '2.4rem',
                }}
              >
                <h1
                  style={{
                    marginBottom: '1rem',
                    fontSize: matches ? '3.125rem' : '6.25rem',
                    letterSpacing: '-3px',
                    fontWeight: 700,
                    lineHeight: '80%',
                    color: PINK,
                    textAlign: matches ? 'center' : 'left',
                  }}
                >
                  Your ads.
                  <br />
                  <span style={{ whiteSpace: 'nowrap' }}>Our insights.</span>
                </h1>
                <span
                  className="subtitle"
                  style={{
                    color: '#fff',
                    lineHeight: matches ? '1.7rem' : '2.5rem',
                    fontWeight: matches ? 300 : 300,
                    fontSize: matches ? '1.375rem' : '2.25rem',
                    marginBottom: '36px',
                    textAlign: matches ? 'center' : 'left',
                  }}
                >
                  <strong>Insights</strong> is a collection of data across your channels and campaigns. We present your
                  essential ad data in a super accessible and detailed dashboard — so you can get to know your ads
                  better.
                </span>
                <ColorButton
                  variant="contained"
                  onClick={handleClose}
                  sx={{ alignSelf: matches ? 'center' : 'start', borderRadius: '21px', padding: '12px 15px 12px 15px' }}
                >
                  Check it out
                </ColorButton>
              </div>
              <img
                src="https://c1.webien.cloud/amandaai.com-stage/app/uploads/2022/11/ezgifcom-gif-maker.gif"
                srcSet="https://c1.webien.cloud/amandaai.com-stage/app/uploads/2022/11/ezgifcom-gif-maker.gif"
                alt="Cross Channel Insights"
                loading="lazy"
                width={matches ? 300 : 400}
                style={{
                  height: 'auto',
                  objectFit: 'contain',
                  position: 'relative',
                  bottom: matches ? '60px' : '-140px',
                }}
              />
              {/* </div> */}
            </Stack>
          </div>
        </Container>
      </Backdrop>
    </>
  );
};

export default SplashScreen;
