import http from 'Utils/Http';
import { InsightsRequest } from '../../Types/insightstypes';

const fetchKeywordData = async (params: InsightsRequest) => {
  const { data } = await http.post('/api/insights/keyworddata', { ...params });

  return data;
};

export default fetchKeywordData;
