import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

const LoadingContext = React.createContext();

export function useIsLoading() {
  return useContext(LoadingContext);
}

export function LoadingProvider({ children }) {
  const [isLoadingGlobally, setIsLoadingGlobally] = useState(false);

  const loading = useMemo(
    () => ({
      isLoadingGlobally,
      setIsLoadingGlobally,
    }),
    [isLoadingGlobally]
  );

  return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
}

LoadingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
