import { ApartmentOutlined, BulbOutlined, HomeOutlined } from '@ant-design/icons';
import { Segmented } from 'antd';
import React from 'react';
import { useAuth } from '../../Context/AuthContext';
import { useClient } from '../../Context/ClientContext';

function AppSwitcher() {
  const { user, setactiveclient, activeclient } = useAuth();
  const { clientid } = useClient();
  const portals = {
    INHOUSEPORTAL: {
      value: 'INHOUSEPORTAL',
      icon: <HomeOutlined style={{ color: 'black' }} />,
    },
    PARTNERPORTAL: {
      value: 'PARTNERPORTAL',
      icon: <ApartmentOutlined style={{ color: 'black' }} />,
    },
    CLIENTPORTAL: {
      value: 'CLIENTPORTAL',
      icon: <BulbOutlined style={{ color: 'black' }} />,
    },
  };

  const portaloptions = user?.app_access_choices?.map((app) => portals[app.name]);

  return clientid === 'amandaai' || clientid === 'groupm' ? (
    <Segmented
      onChange={(value) => setactiveclient(value)}
      style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
      size="medium"
      options={portaloptions}
      defaultValue={activeclient}
    />
  ) : null;
}

export default AppSwitcher;
