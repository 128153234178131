import { Space } from 'antd';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';
import { useClient } from '../../Context/ClientContext';

function AppControls({ children }) {
  const appcontrols = useRef(null);
  const { clientid } = useClient();

  useLayoutEffect(() => {
    if (!appcontrols.current) return;
    gsap.to(appcontrols.current, { right: 7, opacity: 1, duration: 0.2, delay: 0.4 });
  }, [appcontrols.current, clientid]);

  return (
    clientid && (
      <div ref={appcontrols} style={{ opacity: 0, position: 'absolute', top: '7px', right: '-40px', zIndex: 900 }}>
        <Space>{children}</Space>
      </div>
    )
  );
}

AppControls.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppControls;
