import axios from 'axios';
import { useEffect } from 'react';
import { useOrganizations } from 'Store/PartnerPortal/store.tsx';

function StateLoader({ children }) {
  const organizations = useOrganizations();

  useEffect(() => {
    if (organizations.organizations.length > 0) return;
    organizations.setIsLoading(true);
    axios
      .get('/api/organizations')
      .then((result) => {
        organizations.load(result.data);
        organizations.setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return children(organizations);
}
export default StateLoader;
