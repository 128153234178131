import EastIcon from '@mui/icons-material/East';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { AggregatedDataType } from 'Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { capitalizeSentence } from 'Utils/Helpers';
import { BEIGE, BLUE, ORANGE } from 'Utils/colors';
import { humanReadable } from 'Utils/humanReadableTitles';
import { regressionLoess } from 'd3-regression';
import React, { Suspense } from 'react';

interface Datum {
  x?: Date | number | string;
  y?: Date | number | string;
  [key: string]: any;
}

interface Serie {
  id: string | number;
  sortKey?: string | number;
  data: Datum[];
  [key: string]: any;
}

type Props = {
  data: Serie;
  hasTrendLine?: boolean;
  isLoading?: boolean;
  foregroundColor?: string[];
  backgroundColor?: string;
};

const CustomTooltip = ({ point }: PointTooltipProps) => (
  <BasicTooltip
    id={
      <span>
        <strong>{point.data.xFormatted}</strong> : {point.data.yFormatted}
      </span>
    }
    enableChip={true}
    color={point.serieColor}
  />
);

function NivoLineChart({
  data,
  hasTrendLine = false,
  isLoading,
  foregroundColor = [ORANGE, BLUE],
  backgroundColor = BEIGE,
}: Props) {
  const { portalUser } = useInsightsQuery();
  const hasValues = data?.data?.length > 0;
  let dataCopy = structuredClone(data);
  const datalength = dataCopy?.data?.length;
  let chartData = [dataCopy];

  dataCopy.id = humanReadable.insightsKPIs[dataCopy.id as keyof AggregatedDataType](portalUser);

  if (hasTrendLine) {
    const trendLine = regressionLoess()
      .x((d: Datum) => dayjs(d.x))
      .y((d: Datum) => d.y)
      .bandwidth(1)(dataCopy?.data);

    const trendLineData = {
      id: capitalizeSentence(`trend`),
      data: data?.data.map((d, i) => {
        return {
          x: dayjs(d.x).format('YYYY-MM-DD'),
          y: trendLine[i] === undefined ? 0 : trendLine[i][1],
        };
      }),
    };

    chartData = [trendLineData, dataCopy];
  }

  return hasValues ? (
    <Suspense fallback={<CircularProgress />}>
      <ResponsiveLine
        isInteractive
        animate={false}
        data={chartData}
        curve="linear"
        margin={{ top: 20, right: 40, bottom: 90, left: 50 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: true,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        lineWidth={2}
        yFormat={
          dataCopy?.label
            ? humanReadable.insightsKPIValueFormat[dataCopy?.label as keyof AggregatedDataType](portalUser)
            : ' >-.2r'
        }
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%b %d',
          tickValues: 5,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 42,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickValues: 3,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enablePoints={true}
        enableGridX={true}
        enableGridY={true}
        pointSize={datalength <= 90 ? 5 : 3}
        pointBorderWidth={2}
        colors={foregroundColor}
        useMesh={true}
        crosshairType="bottom-left"
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 50,
            itemsSpacing: 0,
            itemDirection: 'top-to-bottom',
            itemWidth: 110,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'square',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        tooltip={(node) => <CustomTooltip point={node.point} />}
      />
    </Suspense>
  ) : isLoading ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80%',
        width: '100%',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80%',
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={1}>
        <EastIcon fontSize="small" />
        <div>First select filters and then a property to view chart</div>
      </Stack>
    </div>
  );
}

export default NivoLineChart;