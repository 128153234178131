import { Select, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { fetchAllPartnerOrganizations, fetchDescendantOrganizations } from '../../Services/organizationService';

function ParentSwitcher({ descendantorganizations }) {
  const [parentOrganizations, setParentOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchAllPartnerOrganizations()
      .then((list) => setParentOrganizations(list))
      .catch(() => message.error('Could not fetch parent organizations'))
      .then(() => setIsLoading(false));
  }, []);

  const handleParentChange = (uuid) => {
    descendantorganizations.setIsLoading(true);
    fetchDescendantOrganizations(uuid).then((res) => {
      descendantorganizations.load(res);
      descendantorganizations.setIsLoading(false);
    });
  };

  const parentorganizationoptions = parentOrganizations.map((parent) => ({ value: parent.uuid, label: parent.name }));

  return (
    <Select
      loading={isLoading}
      size="medium"
      showSearch
      style={{ width: 200 }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
      options={parentorganizationoptions}
      onChange={(val) => handleParentChange(val)}
    />
  );
}

ParentSwitcher.propTypes = {
  descendantorganizations: PropTypes.object.isRequired,
};

export default ParentSwitcher;
