import React from 'react';

type Props = {
  width: number;
  color?: string;
};

function AmandaAiLogo({ width, color = '#fff' }: Props) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" id="a" width={width} viewBox="0 0 496.06 48.48">
    //   <polygon points="465.53 0 488.14 48.48 496.06 48.48 473.45 0 465.53 0"></polygon>
    //   <polygon points="244.59 35.69 218.95 0 210.89 0 210.89 48.48 217.89 48.48 217.89 10.47 245.15 48.48 251.59 48.48 251.59 0 244.59 0 244.59 35.69"></polygon>
    //   <path fill={"#fff"} d="M306.87,3.99c-3.89-2.5-9.74-3.99-15.65-3.99h-15.73V48.48h15.73c5.91,0,11.76-1.49,15.65-3.99h0c6.79-4.42,11.01-12.13,11.01-20.12s-4.22-16.04-11.01-20.37Zm-24.39,2.58h6.55c4.93,0,10.1,.26,14.51,3.33h0c4.59,3.16,7.33,8.49,7.33,14.27s-2.58,11.22-6.73,14.22c-4.46,3.24-9.42,3.51-14.12,3.51h-7.55V6.57Z"></path>
    //   <polygon points="118.12 0 116.48 0 114.76 0 96.75 38.4 78.75 0 77.03 0 75.39 0 70.03 0 70.03 48.48 77.03 48.48 77.03 12.98 93.83 48.48 94.58 48.48 98.93 48.48 99.68 48.48 116.48 12.98 116.48 48.48 123.48 48.48 123.48 0 118.12 0"></polygon>
    //   <polygon points="29.86 0 22.61 0 0 48.48 7.92 48.48 26.23 9.51 44.55 48.48 52.47 48.48 29.86 0"></polygon>
    //   <polygon points="170.76 0 163.51 0 140.9 48.48 148.83 48.48 167.14 9.51 185.45 48.48 193.37 48.48 170.76 0"></polygon>
    //   <polygon points="358.46 0 351.21 0 328.6 48.48 336.52 48.48 354.83 9.51 373.14 48.48 381.06 48.48 358.46 0"></polygon>
    //   <polygon points="439.46 0 432.21 0 409.6 48.48 417.52 48.48 435.83 9.51 454.15 48.48 462.07 48.48 439.46 0"></polygon>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" id="a" width={width} viewBox="0 0 496.06 48.48">
      <path fill={color} d="M465.53 0 488.14 48.48 496.06 48.48 473.45 0 465.53 0z"></path>
      <path
        fill={color}
        d="M244.59 35.69 218.95 0 210.89 0 210.89 48.48 217.89 48.48 217.89 10.47 245.15 48.48 251.59 48.48 251.59 0 244.59 0 244.59 35.69z"
      ></path>
      <path
        fill={color}
        d="M306.87,3.99c-3.89-2.5-9.74-3.99-15.65-3.99h-15.73V48.48h15.73c5.91,0,11.76-1.49,15.65-3.99h0c6.79-4.42,11.01-12.13,11.01-20.12s-4.22-16.04-11.01-20.37Zm-24.39,2.58h6.55c4.93,0,10.1,.26,14.51,3.33h0c4.59,3.16,7.33,8.49,7.33,14.27s-2.58,11.22-6.73,14.22c-4.46,3.24-9.42,3.51-14.12,3.51h-7.55V6.57Z"
      ></path>
      <path
        fill={color}
        d="M118.12 0 116.48 0 114.76 0 96.75 38.4 78.75 0 77.03 0 75.39 0 70.03 0 70.03 48.48 77.03 48.48 77.03 12.98 93.83 48.48 94.58 48.48 98.93 48.48 99.68 48.48 116.48 12.98 116.48 48.48 123.48 48.48 123.48 0 118.12 0z"
      ></path>
      <path fill={color} d="M29.86 0 22.61 0 0 48.48 7.92 48.48 26.23 9.51 44.55 48.48 52.47 48.48 29.86 0z"></path>
      <path
        fill={color}
        d="M170.76 0 163.51 0 140.9 48.48 148.83 48.48 167.14 9.51 185.45 48.48 193.37 48.48 170.76 0z"
      ></path>
      <path
        fill={color}
        d="M358.46 0 351.21 0 328.6 48.48 336.52 48.48 354.83 9.51 373.14 48.48 381.06 48.48 358.46 0z"
      ></path>
      <path
        fill={color}
        d="M439.46 0 432.21 0 409.6 48.48 417.52 48.48 435.83 9.51 454.15 48.48 462.07 48.48 439.46 0z"
      ></path>
    </svg>
  );
}

export default AmandaAiLogo;
