import React from 'react';
// @ts-ignore
import Microsoft_Bing_logo from './Microsoft_Bing_logo.png';

function MicrosoftLogoContainer() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={Microsoft_Bing_logo} style={{ width: '170px', alignSelf: 'center' }} />
    </div>
  );
}

export default MicrosoftLogoContainer;
