import React from 'react';
// @ts-ignore
import PartnerLogo from './PartnerLogo.png';

function PartnerLogoContainer() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={PartnerLogo} style={{ width: '170px', alignSelf: 'center' }} />
    </div>
  );
}

export default PartnerLogoContainer;
