import axios from 'axios';

const fetchUserLogins = async (args) => {
  const { data } = await axios.get(`/api/userlogins/${args}`);
  return data;
};

const fetchUserEvents = async (args) => {
  const { data } = await axios.get(`/api/usereventlog/${args}`);
  return data;
};

export { fetchUserLogins, fetchUserEvents };
