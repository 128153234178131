export const enumerateDaysBetweenDates = (startdate: any, enddate: any) => {
  let d = startdate;
  const datelist = [];
  while (d.isSameOrBefore(enddate)) {
    datelist.push(d.format('YYYY-MM-DD'));
    d = d.add(1, 'days');
  }
  return datelist;
};

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const isNegativeZero = (x: number) => x === 0 && 1 / x === -Infinity;

export const isObject = (sample: unknown) =>
  typeof sample === 'object' &&
  !Array.isArray(sample) &&
  sample !== null &&
  sample !== undefined &&
  typeof sample !== 'function';

export const isEmptyObject = (obj: unknown) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;


export function capitalizeSentence(sentence: string): string {
  if (!sentence) {
    throw new Error("Input string is empty.");
  }

  const words = sentence.split(" ");

  if (words.length === 0) {
    throw new Error("Input string contains no words.");
  }

  const capitalizedWords = words.map((word) => {
    if (!word) {
      throw new Error("Input string contains an empty word.");
    }

    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(" ");
}