import fetchAggregatedDataByDate from 'Services/InsightsServices/fetchAggregatedDataByDate';
import { PINK } from 'Utils/colors';
import React from 'react';
import { useQuery } from 'react-query';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import AggregatedDataTableView from './AggregatedDataTableView';
import AggregatedDataByDateMultiView from './AggregatedDataByDateMultiView';
import ErrorBoundary from './Components/ErrorBoundary';
import Grid from '@mui/material/Grid';
import fetchAggregatedData from 'Services/InsightsServices/fetchAggregatedData';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { AggregatedDataByDateType } from 'Types/insightstypes';
import LoadingScreen from './Components/LoadingScreen';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type QueryReturnType = {
  data: { data: AggregatedDataByDateType[] };
  isLoading: boolean;
};

function AggregatedDataByDateView() {
  const { queryparams, isReadyToRun, shallCompare, alternateStartDate, alternateEndDate } = useInsightsQuery();

  const { data: aggregatedbydate, isLoading: isLoadingAggregatedByDate } = useQuery<QueryReturnType>({
    queryKey: ['aggregateddatabydate', { ...queryparams }],
    queryFn: () => fetchAggregatedDataByDate(queryparams),
    enabled: isReadyToRun,
  });

  const { data: aggregatedbydateComparisonData, isLoading: isLoadingAggregatedByDateComparisonData } =
    useQuery<QueryReturnType>({
      queryKey: [
        'aggregateddatabydateComparisonData',
        { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate },
      ],
      queryFn: () =>
        fetchAggregatedDataByDate({
          ...queryparams,
          startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
          enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
        }),
      enabled: isReadyToRun && shallCompare,
    });

  const { data: aggregatedtotal, isLoading: isLoadingAggregatedTotal } = useQuery({
    queryKey: ['aggregateddata', { ...queryparams }],
    queryFn: () => fetchAggregatedData(queryparams),
    enabled: isReadyToRun,
  });

  const { data: aggregatedTotalComparison, isLoading: isLoadingAggregatedTotalComparison } = useQuery({
    queryKey: ['aggregateddataComparison', { ...queryparams }],
    queryFn: () =>
      fetchAggregatedData({
        ...queryparams,
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
    enabled: isReadyToRun && shallCompare,
  });

  const tooLittleData =
    (!aggregatedbydate?.data?.data || aggregatedbydate?.data?.data?.length === 0) && !isLoadingAggregatedByDate;

  const tooLittleComparisonData =
    shallCompare &&
    (!aggregatedTotalComparison?.data?.data ||
      !aggregatedbydateComparisonData?.data?.data ||
      aggregatedbydateComparisonData?.data?.data?.length === 0) &&
    !isLoadingAggregatedByDateComparisonData;

  return isLoadingAggregatedByDate || isLoadingAggregatedTotal || isLoadingAggregatedByDateComparisonData ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData || tooLittleComparisonData ? (
        <>
          {tooLittleData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data. Please select a different date range.
            </Alert>
          )}
          {tooLittleComparisonData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data to compare with the selected date range.
            </Alert>
          )}
        </>
      ) : (
        <AggregatedDataByDateMultiView
          dataByDate={aggregatedbydate?.data?.data}
          comparisondata={aggregatedbydateComparisonData?.data?.data || []}
          dataTotal={aggregatedtotal?.data?.data[0] || null}
          comparisonTotal={aggregatedTotalComparison?.data?.data[0] || null}
        />
      )}
    </ErrorBoundary>
  );
}

export default AggregatedDataByDateView;
