import fetchKeywordData from 'Services/InsightsServices/fetchKeywordData';
import { InsightsKPISchemaType, keyworddata } from 'Types/insightstypes';
import { LIGHTBLUE } from 'Utils/colors';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useInsightsQuery } from '../../Context/InsightsQueryContext';
import SimpleTableList from './Components/SimpleTableList';
import { ErrorBoundary } from '@sentry/react';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import helptexts from 'Assets/JSON/helptexts';
import { qualityscores } from '../../Mock/Keywords/index';
import LoadingScreen from './Components/LoadingScreen';

type Props = {};

function KeywordDataTableView({}: Props) {
  const { queryparams, isReadyToRun } = useInsightsQuery();
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['keyworddata', { ...queryparams }],
    queryFn: () => fetchKeywordData(queryparams),
    enabled: isReadyToRun,
  });
  const keyworddata: keyworddata[] = data?.data?.data || [];

  /**
   * Keyword data is calculated by multiplying a scaled down quality score with conversion value
   * The result is then sorted in descending order
   * The top 15 keywords are then selected
   */
  const sortedAndWeightedData: { keyword: string; qualityscore: number }[] = useMemo(() => {
    const weightedKeywordData = keyworddata
      .filter((item) => item.qualityscore > 0)
      .map((item) => {
        const qualityscore = item.qualityscore * 0.01 * item.conversionvalue;
        return { ...item, qualityscore };
      });
    return weightedKeywordData
      .sort((a, b) => b.qualityscore - a.qualityscore)
      .slice(0, 15)
      .map((item) => ({ keyword: item.keyword, qualityscore: item.qualityscore }));
  }, [keyworddata]);

  /**
   * Keyword data is sorted by quality score
   * The result is then sorted in descending order
   * The result is then sorted by click through rate
   * The result is then sorted in descending order
   * The top 15 keywords are then selected
   * */
  const keyWordsWithPotential: { keyword: string; qualityscore: number; clickthroughrate: number }[] = useMemo(() => {
    const potentialKeywords: keyworddata[] = keyworddata
      // Larger QS than 0 because 1 is the lowest possible quality score. 0 would equal null.
      ?.filter((item) => item.qualityscore <= 5 && item.qualityscore > 0)
      .filter((item) => item.clickthroughrate > 0);

    const potentialKeywordsSortedByQualityScore = potentialKeywords.sort((a, b) => b.qualityscore - a.qualityscore);
    const potentialKeywordsSortedByQualityScoreSortedByCTR = potentialKeywordsSortedByQualityScore.sort(
      (a, b) => b.clickthroughrate - a.clickthroughrate
    );
    const qualityscoreSelectionSortedByCTRTop15 = potentialKeywordsSortedByQualityScoreSortedByCTR
      .slice(0, 15)
      .map((item) => ({
        keyword: item.keyword,
        qualityscore: item.qualityscore,
        clickthroughrate: item.clickthroughrate,
      }));

    return qualityscoreSelectionSortedByCTRTop15;
  }, [keyworddata]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Grid item style={{ position: 'relative', height: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ErrorBoundary>
            <SimpleTableList
              tabledata={sortedAndWeightedData}
              title="Weighted keyword list"
              helptext={helptexts.WEIGHTED_KEYWORDS.content}
            />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ErrorBoundary>
            <SimpleTableList
              tabledata={keyWordsWithPotential}
              title="Potential keyword list"
              helptext={helptexts.POTENTIAL_KEYWORDS.content}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KeywordDataTableView;
