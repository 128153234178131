import { InsightsRequest } from 'Types/insightstypes';
import http from 'Utils/Http';

const fetchInsightsFilters = async (params: InsightsRequest) => {
  const { data } = await http.get(`/api/insights/filters/${params.portaluser}`);

  return data;
};

export default fetchInsightsFilters;
