import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import { ErrorBoundary } from '@sentry/react';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import useChartDimensions from 'Hooks/useChartDimensions';
import fetchKeywordData from 'Services/InsightsServices/fetchKeywordData';
import { keyworddata } from 'Types/insightstypes';
import { BEIGE } from 'Utils/colors';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import BeeSwarmCanvasChart from './Components/BeeSwarmCanvasChart';
import BeeSwarmChart from './Components/BeeSwarmChart';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';

type Props = {};

const chartSettings = {
  marginTop: 30,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 40,
};

const KeywordDataBeeSwarmChartView = (props: Props) => {
  const { queryparams, isReadyToRun, setCompareEnabled } = useInsightsQuery();
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['keyworddata', { ...queryparams }],
    queryFn: () => fetchKeywordData(queryparams),
    enabled: isReadyToRun,
  });

  const [ref, dms] = useChartDimensions(chartSettings);
  const keyworddata: keyworddata[] = data?.data?.data || [];
  const tooLittleData = (!keyworddata || keyworddata?.length === 0) && !isLoading;

  /**
   * Disable comparison because there's no reason to compare keywords period over period.
   */
  useEffect(() => {
    setCompareEnabled(false);
    return () => {
      setCompareEnabled(true);
    };
  }, []);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData ? (
        <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
          <AlertTitle>Info</AlertTitle>
          There's not enough data. Please select a different date range.
        </Alert>
      ) : (
        <Grid
          item
          style={{ width: '100%', padding: '12px', marginBottom: '12px', backgroundColor: BEIGE, overflow: 'visible' }}
        >
          <h2>Keyword Plot</h2>
          <p>
            This chart shows keywords organized horizontally by their Quality Score. The size of the bubble represents
            the conversion value amount.
          </p>
          <div className="Chart__wrapper" ref={ref} style={{ height: '600px', overflow: 'visible' }}>
            {keyworddata?.length > 2000 ? (
              <BeeSwarmCanvasChart keyworddata={keyworddata} />
            ) : (
              <BeeSwarmChart keyworddata={keyworddata} />
            )}
          </div>
        </Grid>
      )}
    </ErrorBoundary>
  );
};

export default KeywordDataBeeSwarmChartView;
