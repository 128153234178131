import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import fetchInsightsFilters from 'Services/InsightsServices/fetchInsightsFilters';
import { Filters } from 'Types/insightstypes';
import { capitalizeSentence } from 'Utils/Helpers';
import { PINK } from 'Utils/colors';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

function PlatformSelector() {
  const [platformOptions, setPlatformOptions] = useState<string[]>([]);
  const { platforms, queryparams, platformsEnabled, setPlatforms, portalUser } = useInsightsQuery();
  const { data, isLoading } = useQuery<Filters>({
    queryKey: ['insightsfilters', { ...queryparams }],
    queryFn: () => fetchInsightsFilters(queryparams),
    enabled: !!portalUser && platforms?.length === 0,
    refetchInterval: false,
  });

  useEffect(() => {
    if (data?.data?.data?.platforms) {
      setPlatforms(data?.data?.data?.platforms.filter((platform) => platform !== null) as string[]);
      setPlatformOptions(data?.data?.data?.platforms.filter((platform) => platform !== null) as string[]);
    }
  }, [data?.data?.data?.platforms?.toString()]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const valueToAdd = event.target.value;
    event.target.checked
      ? setPlatforms((prevPlatforms) => [...prevPlatforms, valueToAdd])
      : setPlatforms((prevPlatforms) => prevPlatforms.filter((value) => value !== valueToAdd));
  }

  return (
    <Grid>
      <Grid item>
        <FormControl>
          <FormGroup aria-label="position" row>
            {isLoading ? (
              <Skeleton animation="wave" sx={{ width: '200px', padding: '9px' }} />
            ) : (
              platformOptions?.map((platform) => (
                <FormControlLabel
                  key={platform}
                  value={platform}
                  control={
                    <Checkbox
                      disabled={!platformsEnabled}
                      checked={platforms.includes(platform)}
                      onChange={handleChange}
                      sx={{
                        color: PINK,
                        '&.Mui-checked': {
                          color: PINK,
                        },
                      }}
                      inputProps={{
                        'aria-label': platform,
                      }}
                    />
                  }
                  label={capitalizeSentence(platform)}
                  labelPlacement="end"
                />
              ))
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default PlatformSelector;
