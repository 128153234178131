import React, { useState } from 'react';

import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import useChartDimensions from 'Hooks/useChartDimensions';
import { CampaignTypeData, CampaignType } from 'Types/insightstypes';
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  children: (arg: Boolean) => React.ReactNode;
};

const chartSettings = {
  marginTop: 30,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 40,
};

function ChartSwitchWrapper({ children }: Props) {
  const [chartTypeSwitch, setChartTypeSwitch] = useState(false);

  const [ref, dms] = useChartDimensions(chartSettings);
  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Show both periods as stacked lines">
          <IconButton sx={{ margin: '0 !important' }} aria-label="stacked" onClick={() => setChartTypeSwitch(false)}>
            <StackedLineChartIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Tooltip>
        <Switch
          sx={{ margin: '0 !important' }}
          size="small"
          checked={chartTypeSwitch}
          onChange={() => setChartTypeSwitch(!chartTypeSwitch)}
        />
        <Tooltip title="Show only the difference between the two periods">
          <IconButton sx={{ margin: '0 !important' }} aria-label="delta" onClick={() => setChartTypeSwitch(true)}>
            <ChangeHistoryIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <div className="Chart__wrapper" ref={ref} style={{ height: '220px' }}>
        {children(chartTypeSwitch)}
      </div>
    </div>
  );
}

export default ChartSwitchWrapper;
