import { Progress, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function IdlePrompt(props) {
  const { remainingTime, promptBeforeIdle } = props;
  const [seconds, setSeconds] = useState(promptBeforeIdle);

  useEffect(() => {
    const remainingTimeInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(remainingTime());
      }
      if (seconds === 0) {
        clearInterval(remainingTimeInterval);
      }
    }, 100);
    return () => {
      clearInterval(remainingTimeInterval);
    };
  }, [remainingTime, seconds]);

  return (
    <Space direction="vertical" size="small">
      <p>Click below to stay signed in.</p>
      <Progress
        showInfo={false}
        strokeColor="primary"
        strokeLinecap="square"
        percent={((promptBeforeIdle - seconds) / promptBeforeIdle) * 100}
      />
    </Space>
  );
}

IdlePrompt.propTypes = {
  remainingTime: PropTypes.number.isRequired,
  promptBeforeIdle: PropTypes.number.isRequired,
};

export default IdlePrompt;
