import Divider from '@mui/material/Divider';
import { BarTooltipProps } from '@nivo/bar';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import { keyworddata } from 'Types/insightstypes';
import { BLACK } from 'Utils/colors';
import * as d3 from 'd3';
import React, { memo, useEffect, useState, useTransition } from 'react';

import { useInsightsQuery } from 'Context/InsightsQueryContext';
import LoadingScreen from '../LoadingScreen';

import './beeswarm.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  keyworddata: keyworddata[];
};

const CustomTooltip = ({
  data,
  id,
  color,
  value,
}: Omit<BarTooltipProps<keyworddata>, 'hidden' | 'indexValue' | 'label' | 'index' | 'formattedValue'>) => {
  const { portalUser } = useInsightsQuery();
  const qsFormat = d3.format('.2f');
  const cvFormat = d3.format('.2f');
  const clicksFormat = d3.format('.2s');
  const ctrFormat = d3.format('.2%');
  const impressionsFormat = d3.format('.2f');

  return (
    <div style={{ backgroundColor: '#fff', padding: '7px' }}>
      <Divider>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <span style={{ display: 'block', width: 12, height: 12, background: color, marginRight: '4px' }}>&nbsp;</span>
          <span>
            <strong>{id}</strong>
          </span>
        </div>
      </Divider>
      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
        <li>
          <strong>Quality Score:</strong> {qsFormat(value)}
        </li>
        <li>
          <strong>Conversion Value:</strong> {cvFormat(data.conversionvalue)}
        </li>

        <li>
          <strong>Clicks:</strong> {clicksFormat(data.clicks)}
        </li>
        <li>
          <strong>CTR:</strong> {ctrFormat(data.clickthroughrate)}
        </li>
        <li>
          <strong>Impressions:</strong> {impressionsFormat(data.impressions)}
        </li>
      </ul>
    </div>
  );
};

const BeeSwarmChart = memo(function BeeSwarm({ keyworddata }: Props) {
  const [isPending, startTransition] = useTransition();
  const [isRendering, setIsRendering] = useState(true);
  const conversionvalueDomain = d3.extent(keyworddata, (d) => d.conversionvalue);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const sizes = matches ? [5, 200] : [5, 50];
  const margins = matches
    ? { top: 80, right: 40, bottom: 80, left: 40 }
    : { top: 60, right: 15, bottom: 120, left: 15 };

  // Data sorted by conversion value to make the chart render the smallest dots on top
  // and filter out keywords with 0 clicks
  const chartData = structuredClone(keyworddata)
    .sort((a, b) => b.conversionvalue - a.conversionvalue)
    .filter((d) => d.clicks > 0);

  useEffect(() => {
    startTransition(() => {
      setIsRendering(false);
    });
  }, []);

  return isRendering || isPending ? (
    <LoadingScreen />
  ) : (
    <ResponsiveSwarmPlot
      isInteractive
      data={chartData}
      groups={['qualityscore']}
      id="keyword"
      value="qualityscore"
      valueFormat=" >-.2f"
      valueScale={{ type: 'linear', min: 0, max: 10, reverse: false }}
      colors={'rgba(70,190,255, 0.7)'}
      size={{
        key: 'conversionvalue',
        values: conversionvalueDomain,
        sizes: sizes,
      }}
      borderWidth={1}
      borderColor={BLACK}
      spacing={9}
      layout="horizontal"
      gap={3}
      forceStrength={8}
      simulationIterations={160}
      colorBy="id"
      margin={margins}
      axisRight={null}
      axisBottom={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Quality Score',
        legendPosition: 'end',
        legendOffset: 60,
      }}
      axisTop={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Quality Score',
        legendPosition: 'start',
        legendOffset: -40,
      }}
      axisLeft={null}
      tooltip={(props) => (
        <CustomTooltip data={props.data} id={props.id} color={props.color as string} value={props.value as number} />
      )}
    />
  );
});

export default BeeSwarmChart;
