import { getCookie } from 'Utils/Helpers';
import http from 'Utils/Http';
import { InsightsRequest } from '../../Types/insightstypes';

const fetchCampaignTypeData = async (params: InsightsRequest) => {
  const { data } = await http.post('/api/insights/campaigntypedata', { ...params });

  return data;
};

export default fetchCampaignTypeData;
