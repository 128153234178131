import EastIcon from '@mui/icons-material/East';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { admitUser, passwordReset } from '../../../Services/admitService';
import './flip.css';

import ErrorList from './AdmittanceErrorListView';

// http://127.0.0.1:8000/admit/bHdBQUFBPT0kNnUyNHg1ZnFyZTk4emV5Y0paNDZBTllFdERKNnREYTQ%3D

const colors = ['#46beff', '#fe8cb5', '#000000', '#fc5607', '#1f969a'];
const bgcolor = colors[Math.floor(Math.random() * colors.length)];

function Admit() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tokenKey, setTokenKey] = useState(false);
  const [error, setError] = useState(false);
  const [passwordErrorList, setPasswordErrorList] = useState([]);
  const [username, setUsername] = useState('');
  const [helpTextsList, setHelpTextsList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    setIsLoading(true);
    if (location === undefined) return;
    const urlkey = location?.pathname.slice(location?.pathname.lastIndexOf('/') + 1);
    admitUser(urlkey)
      .then((data) => {
        if (data.token === undefined) throw ReferenceError('No token');
        if (data.key === undefined) throw ReferenceError('No key');
        const { token } = data;
        const { key } = data;
        const { username } = data;
        const helptexts = data.help;
        setHelpTextsList(helptexts);
        setUsername(username);
        setTokenKey({ key, token });
        setIsLoading(false);
      })
      .catch((e) => {
        setError(true);
        setHasFailed(true);
        setIsLoading(false);
      });
  }, [location.pathname]);

  const onFinish = (values, { setSubmitting }) => {
    setSubmitting(true);

    passwordReset({ ...values, ...tokenKey })
      .then((res) => {
        setSuccess(true);
        navigate('/', { replace: true });
      })
      .catch((err) => {
        setPasswordErrorList(err.response.data.message);
        setHasFailed(true);
      })
      .then(() => setSubmitting(false));
  };

  return (
    <Container
      sx={{
        backgroundColor: '#ffe128 !important',
        background: '#ffe128 !important',
        maxWidth: '100% !important',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        height: '100vh',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            position: 'relative',
            typography: 'body2',
            bgcolor: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            typography: 'body2',
            bgcolor: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              position: 'relative',
              transformStyle: 'preserve-3d',
              minHeight: '180px',
              width: '420px',
              transform: 'translate3d(0,0,0)',
            }}
            className={error ? 'flip-2-ver-right-2' : ''}
          >
            <Zoom in timeout={550}>
              <Card
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '180px',
                  width: '100%',
                }}
                elevation={8}
                className="front_face"
              >
                {error ? (
                  <Box
                    sx={{
                      backgroundColor: bgcolor,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '180px',
                    }}
                  >
                    <Box>
                      <img
                        src="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                        srcSet="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                        alt="Amanda AI logotype"
                        loading="lazy"
                        // style={{ filter: 'invert(1)' }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <ErrorList errors={passwordErrorList} />
                    <Formik
                      initialValues={{
                        username,
                        password: '',
                        passwordrepeat: '',
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.password) {
                          errors.password = 'Password is required';
                        }
                        if (values.password !== values.passwordrepeat) {
                          errors.passwordrepeat = 'Passwords needs to match';
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => onFinish(values, { setSubmitting })}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        submitForm,
                        setSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <CardContent>
                            <Box
                              sx={{
                                marginBottom: '1.2rem',
                              }}
                            >
                              <img
                                src="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                                srcSet="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                                alt="Amanda AI logotype"
                                loading="lazy"
                                width={120}
                                style={{ filter: 'invert(1)' }}
                              />
                            </Box>
                            <Stack direction="column" spacing={2}>
                              <Box>
                                <Typography variant="subtitle1" gutterBottom>
                                  Please choose a password
                                </Typography>
                                {helpTextsList.length > 0 ? (
                                  <List>
                                    {helpTextsList.map((item) => (
                                      <ListItem disablePadding>
                                        <ListItemIcon
                                          sx={{
                                            minWidth: '37px',
                                            marginLeft: 0,
                                            paddingLeft: 0,
                                          }}
                                        >
                                          <EastIcon
                                            sx={{
                                              marginRight: 0,
                                              paddingRight: 0,
                                            }}
                                          />
                                        </ListItemIcon>
                                        {item}
                                      </ListItem>
                                    ))}
                                  </List>
                                ) : null}
                              </Box>
                              <input
                                id="csrfmiddlewaretoken"
                                name="csrfmiddlewaretoken"
                                type="hidden"
                                onChange={handleChange}
                                value={values.csrfmiddlewaretoken}
                              />
                              <FormControl variant="filled" required={false}>
                                <TextField
                                  autoFocus
                                  id="username"
                                  name="username"
                                  label="Username"
                                  variant="filled"
                                  onChange={handleChange}
                                  autoComplete="username"
                                  tabIndex={1}
                                  disabled
                                  value={username}
                                />
                                {errors.username && touched.username && errors.username}
                              </FormControl>
                              <FormControl variant="filled" required>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <FilledInput
                                  id="password"
                                  name="password"
                                  type={showPassword ? 'text' : 'password'}
                                  label="Password"
                                  variant="filled"
                                  onChange={handleChange}
                                  autoComplete="new-password"
                                  required
                                  tabIndex={2}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        sx={{ background: 'transparent' }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        disableRipple
                                        tabIndex={10}
                                      >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                {errors.password && touched.password && errors.password}
                              </FormControl>
                              <FormControl variant="filled" required>
                                <InputLabel htmlFor="passwordrepeat">Repeat password</InputLabel>
                                <FilledInput
                                  id="passwordrepeat"
                                  name="passwordrepeat"
                                  type={showPassword ? 'text' : 'password'}
                                  label="Password"
                                  variant="filled"
                                  onChange={handleChange}
                                  autoComplete="new-password"
                                  required
                                  tabIndex={3}
                                />
                                {errors.passwordrepeat && touched.passwordrepeat && errors.passwordrepeat}
                              </FormControl>
                              {/* <Link href="#">Forgot password?</Link> */}
                            </Stack>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <LoadingButton
                              variant="contained"
                              endIcon={<PersonAddIcon />}
                              color="pink"
                              sx={{ color: 'white' }}
                              loading={isSubmitting}
                              onClick={() => submitForm()}
                              tabIndex={4}
                            >
                              Save
                            </LoadingButton>
                          </CardActions>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
              </Card>
            </Zoom>
            {error && (
              <Card
                elevation={8}
                className="back_face"
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      marginBottom: '1.2rem',
                    }}
                  >
                    <img
                      src="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                      srcSet="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                      alt="Amanda AI logotype"
                      loading="lazy"
                      width={120}
                      style={{ filter: 'invert(1)' }}
                    />
                  </Box>
                  <Typography variant="h5" component="div" gutterBottom>
                    Oops, that didn't work...
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    We´d love to hook you up with an account but please{' '}
                    <Link target="_blank" href="https://amandaai.com/book-demo/">
                      give us a call first.
                    </Link>
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>

          <Box sx={{ width: '100%', visibility: 'hidden' }}>Footer</Box>
        </Box>
      )}
    </Container>
  );
}

export default Admit;
