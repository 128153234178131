import { useEffect, useState } from 'react';

type InitialValue = null | string | number | boolean | (() => null | string | number | boolean);
type StoredValue = null | string | number | boolean;

function getSavedValue(key: string, initialvalue: InitialValue): StoredValue {
  try {
    const savedvalue = JSON.parse(localStorage.getItem(key));
    if (savedvalue) return savedvalue;
  } catch (err) {
    console.error(err);
  }
  if (initialvalue instanceof Function) return initialvalue();
  return initialvalue;
}

function useLocalStorage(key: string, initialvalue: any): [StoredValue, Function] {
  const [value, setValue] = useState(() => getSavedValue(key, initialvalue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
}

export default useLocalStorage;
