import { Modal } from 'antd';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '../../Context/AuthContext';
import IdlePrompt from './IdlePrompt';

const idletimeout = 6 * 60 * 60 * 1000; // 6 hours
const prompttimeout = 60 * 1000; // 60 seconds

function IdleHandler() {
  const { signOut } = useAuth();
  const onIdle = () => {
    // Sign out, then relocate
    Modal.destroyAll();
    signOut();
  };

  const { reset, getRemainingTime } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: idletimeout,
    promptBeforeIdle: prompttimeout,
  });

  function onPrompt() {
    return Modal.info({
      maskClosable: true,
      title: 'You have been idle for some time...',
      content: (
        <IdlePrompt
          onClose={handleClose}
          // eslint-disable-next-line no-use-before-define
          remainingTime={getRemainingTime}
          idleTimeOut={idletimeout}
          promptBeforeIdle={prompttimeout}
        />
      ),
      centered: true,
      okText: 'Still here?',
      onOk() {
        handleClose();
      },
      onCancel() {
        handleClose();
      },
    });
  }

  function handleClose() {
    console.log('handleClose');
    reset();
  }

  return null;
}

export default IdleHandler;
