import { ThemeProvider, createTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import { BLUE, LIGHTBLUE, ORANGE, PINK, YELLOW } from 'Utils/colors';
import 'antd/dist/antd.less';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Zoom } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from './Context/AuthContext';
import AppRoutes from './Routes/AppRoutes';
import './Styles/body.css';

const theme = createTheme({
  palette: {
    primary: {
      main: BLUE,
    },
    secondary: {
      main: ORANGE,
    },
    warning: {
      main: YELLOW,
    },
    info: {
      main: LIGHTBLUE,
    },
    pink: {
      main: PINK,
    },
  },
  typography: {
    fontFamily: [
      '"Suisse Intl"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          WebkitAutofilll: 'red',
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderHoverColor': 'rgba(0, 111, 255, 1)',
          '--TextField-brandBorderFocusedColor': 'rgba(0, 111, 255, 1)',
          '& label.Mui-focused': {
            color: 'rgba(0, 111, 255, 1)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          WebkitAutofill: 'red',
          fontSize: '1.111rem',
          lineHeight: '2rem',
          padding: '12px 4px',
          backgroundColor: 'white',
          '--TextField-brandBorderColor': '#E0E3E7',
          '&:before': {
            borderBottom: '2px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid rgba(0, 111, 255, 1)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid rgba(0, 111, 255, 1)',
          },
        },
      },
    },
  },
});

function LoginApp() {
  const { user, isCheckingLogin } = useAuth();
  const [accept, setAccept] = useState(false);
  const [consentBannerOpen, setConsentBannerOpen] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (document.cookie) {
      const cookienames = [];
      const cookies = document.cookie.split(';');
      cookies.forEach((cookie) => {
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0].trim();
        cookienames.push(cookieName);
        if (cookieName === 'cookieConsent') {
          const cookieValue = cookieParts[1];
          if (cookieValue === 'true') {
            // Initialize Tag Manager
            const tagManagerArgs = {
              gtmId: 'GTM-KF2QRNQ',
            };
            TagManager.initialize(tagManagerArgs);
            setAccept(true);
          }
          if (cookieValue === 'false') {
            setAccept(false);
          }
        }
      });
      // If no cookieConsent cookie, show banner

      if (!cookienames.some((name) => name === 'cookieConsent')) {
        setConsentBannerOpen(true);
      }
    }
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // Expire a Cookie
  function expireCookie(cName, cValue) {
    const date = new Date();
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
  }

  const toggleConsent = (consentGiven) => {
    if (document.cookie) {
      if (consentGiven) {
        const tagManagerArgs = {
          gtmId: 'GTM-KF2QRNQ',
        };
        TagManager.initialize(tagManagerArgs);
        document.cookie = `cookieConsent=true; max-age=31536000; path=/`;
      }
      if (!consentGiven) {
        const cookies = document.cookie.split(';');
        // Clear all cookies except for necessary ones
        cookies.forEach((cookie) => {
          const cookieParts = cookie.split('=');
          const cookieName = cookieParts[0].trim();
          // csrf token is necessary for django
          if (cookieName !== 'csrftoken') {
            expireCookie(cookieName, '');
          }
        });
        // Set cookieConsent to false
        document.cookie = `cookieConsent=false; max-age=31536000; path=/`;
      }
      setAccept(consentGiven);
      setConsentBannerOpen(false);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Drawer anchor="top" open={consentBannerOpen}>
          <Box sx={{ p: 2 }}>
            <h3 style={{ fontWeight: 'bold' }}>Notice</h3>
            <p>
              We and selected third parties use cookies or similar technologies for technical purposes and, with your
              consent, for measurement as specified in the cookie policy. Denying consent may make related features
              unavailable.
            </p>
            <p>
              You can freely give, deny, or withdraw your consent at any time. Use the “🍪 Accept” button to consent.
              Use the “Reject all” button to continue without accepting.
            </p>
            <Stack direction="column">
              <FormControlLabel control={<Switch defaultChecked={accept} name="Measurement" />} label="Measurement" />
              <FormControlLabel control={<Switch defaultChecked name="Necessary" disabled />} label="Necessary" />
            </Stack>
            <Box sx={{ width: '100%', p: 2, justifyContent: 'end', display: 'flex' }}>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={() => toggleConsent(true)}>
                  🍪 Accept all
                </Button>
                <Button variant="outlined" onClick={() => toggleConsent(false)}>
                  Reject all
                </Button>
              </Stack>
            </Box>
          </Box>
        </Drawer>
        <AppRoutes user={user} isCheckingLogin={isCheckingLogin} />
        {!consentBannerOpen && user?.category !== 'STAFF' && (
          <Zoom in unmountOnExit>
            <Fab
              sx={{ position: 'fixed', bottom: '10px', right: '10px', p: 1 }}
              variant="extended"
              size="small"
              aria-label="Cookie preferences"
              onClick={() => setConsentBannerOpen(true)}
            >
              {matches ? '🍪 Cookie preferences' : '🍪'}
            </Fab>
          </Zoom>
        )}
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export default LoginApp;
