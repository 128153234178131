import Dispatcher from 'Components/Dispatcher/Dispatcher';
import Loading from 'Components/Loading';
import { ClientProvider } from 'Context/ClientContext';
import Admit from 'Pages/Login/Login/Admit';
import Login from 'Pages/Login/Login/Login';
import propTypes from 'prop-types';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

type Props = {
  user: object | null;
  isCheckingLogin: boolean;
};

function AppRoutes({ user, isCheckingLogin }: Props) {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          isCheckingLogin ? (
            <Loading />
          ) : user ? (
            <ClientProvider>
              <Dispatcher />
            </ClientProvider>
          ) : (
            <Login />
          )
        }
      />
      <Route path="/admit/*" element={<Admit />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<h1>404 Not found</h1>} />
    </Routes>
  );
}

export default AppRoutes;
