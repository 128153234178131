import { Alert } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const messages = [
        'We apologize for the inconvenience. Our team is currently addressing the issue.',
        "We're experiencing technical difficulties at the moment. Our team is actively working to resolve them.",
        'We regret to inform you that something has gone wrong. Our team is working diligently to fix it.',
        'We apologize for any disruption caused. Our team is aware of the problem and is working on a solution.',
        "We're sorry, but we're encountering an issue. Rest assured, our team is working to rectify it as soon as possible.",
        "We're currently experiencing some problems. Our team is investigating the issue and working to restore normal operations.",
        "Our sincere apologies for the inconvenience. We're aware of the issue and are working to resolve it promptly.",
        'We regret to inform you that an error has occurred. Our team is actively working on resolving the issue.',
        'We apologize for the disruption in service. Our team is engaged in resolving the problem.',
        "We're sorry for the inconvenience. Our team is working diligently to fix the issue and minimize any further impact.",
      ];

      const randomIndex = Math.floor(Math.random() * messages.length);
      const randomMessage = messages[randomIndex];
      return (
        <Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
          {randomMessage}
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
