import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TableHead } from '@mui/material';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { BLACK, LIGHTBLUE } from 'Utils/colors';
import {
  HumanReadableInsightsKPIsType,
  humanReadable,
  HumanReadableInsightsKPIsValueFormatType,
} from 'Utils/humanReadableTitles';
import React from 'react';
import HelpModal from '../HelpModal/HelpModal';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { validateDate } from '@mui/x-date-pickers/internals';
import * as d3 from 'd3';

type Props = {
  tabledata: { [key: string]: string | number }[];
  title?: string;
  helptext?: string;
  foregroundcolor?: string;
  backgroundcolor?: string;
  makeHumanReadble?: boolean;
};

const arrowIcon = [
  <ArrowDropDownIcon sx={{ position: 'relative', bottom: '2px' }} />,
  <ArrowDropUpIcon sx={{ position: 'relative', bottom: '2px' }} />,
];

function SimpleTableList({
  tabledata,
  title,
  foregroundcolor = BLACK,
  backgroundcolor = LIGHTBLUE,
  helptext = '',
  makeHumanReadble = false,
}: Props) {
  const { portalUser } = useInsightsQuery();

  return (
    <TableContainer>
      <Table size="small" aria-label="simple table" sx={{ backgroundColor: backgroundcolor }}>
        {title && (
          <TableHead>
            <TableRow>
              <TableCell colSpan={Object.keys(tabledata[0])?.length} sx={{ border: 'none' }}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="end">
                  <h3>{title}</h3>
                  <HelpModal helptext={helptext} />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              {Object.keys(tabledata[0]).map((item, index) => (
                <TableCell key={item} align={index > 0 ? 'right' : 'left'}>
                  <h4>{humanReadable.insightsKPIs[item as keyof HumanReadableInsightsKPIsType](portalUser) || item}</h4>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody sx={{ backgroundColor: backgroundcolor }}>
          {tabledata.map((item) => {
            return (
              <TableRow
                key={title}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                {Object.entries(item).map((entry, index) => {
                  return (
                    <TableCell
                      component="th"
                      align={index > 0 ? 'right' : 'left'}
                      scope="row"
                      sx={{ borderBottom: '1px solid black', maxWidth: '300px', width: '70%' }}
                    >
                      {validateDate ? (
                        <>
                          {typeof entry[1] === 'number'
                            ? d3.format(
                                humanReadable.insightsKPIValueFormat[
                                  entry[0] as keyof HumanReadableInsightsKPIsValueFormatType
                                ](portalUser)
                              )(entry[1])
                            : entry[1]}
                        </>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SimpleTableList;
