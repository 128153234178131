import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { BEIGE, ORANGE, BLUE } from 'Utils/colors';
import React from 'react';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';

type Props = {};

function DateSelector({}: Props) {
  const matches = useMediaQuery('(min-width:600px)');
  const { dateRange, setStartDate, setEndDate, startDate, endDate } = useInsightsQuery();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12} sx={{ paddingTop: '24px', height: '100%' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="flex-start" alignItems="center">
          <DesktopDatePicker
            disableFuture
            maxDate={dayjs(endDate).subtract(1, 'day').toDate()}
            label="Start date"
            inputFormat="YYYY-MM-DD"
            value={dateRange[0]}
            onChange={(value) => setStartDate(value)}
            renderInput={(params) => <TextField {...params} sx={{ width: '280px' }} size="small" />}
          />
          {matches ? (
            <ArrowCircleRightSharpIcon fontSize="large" sx={{ color: BLUE }} />
          ) : (
            <ArrowDropDownCircleIcon fontSize="large" sx={{ color: BLUE }} />
          )}
          <DesktopDatePicker
            disableFuture
            minDate={dayjs(startDate).add(1, 'day').toDate()}
            maxDate={dayjs().subtract(1, 'day').toDate()}
            label="End date"
            inputFormat="YYYY-MM-DD"
            value={dateRange[1]}
            onChange={(value) => setEndDate(value)}
            renderInput={(params) => <TextField {...params} sx={{ width: '280px' }} size="small" />}
          />
        </Stack>
      </Grid>
    </LocalizationProvider>
  );
}

export default DateSelector;
