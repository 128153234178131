/* eslint-disable */
import React, { useEffect } from 'react';
import { useAuth } from 'Context/AuthContext';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { PortalUser } from 'Types/insightstypes';

const PortalUser = (): null => {
  const { setPortalUser } = useInsightsQuery();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      if (user.category === 'CLIENT') {
        const { id, name, organization_type, return_on_expenditure_type, currency } = user?.organization;
        setPortalUser({ id, name, organization_type, return_on_expenditure_type, currency } as PortalUser);
      }
    }
  }, [user?.uuid]);

  return null;
};

export default PortalUser;
