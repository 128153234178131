import { getCookie } from 'Utils/Helpers';
import axios from 'axios';

const http = axios.create({
  // default config for your axios instance
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  },
  timeout: 30000, // 30 seconds
});

http.interceptors.request.use((config) => {
  /**
   * We´re setting the CSRF token here, because:
   * Setting in the default config is not enough, because it will not be
   * reset after a logout/login cycle. That means, that the old token will
   * be used, even if the user is logged out.
   *
   * Setting it here, will ensure, that the token is always up to date.
   */
  config.headers['X-CSRFToken'] = getCookie('csrftoken');
  return config;
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // custom logic to handle 401 response
      // redirect to login page
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default http;
