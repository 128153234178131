import React from 'react';
import { useAuth } from 'Context/AuthContext';

type Props = { children: React.ReactNode };

const PrivateFunction = ({ children }: Props) => {
  const { user } = useAuth();
  const allowedorganizations = user?.allowed_organizations;
  if (user?.category === 'STAFF' || allowedorganizations?.length > 1) {
    return <>{children}</>;
  }

  return null;
};

export default PrivateFunction;
