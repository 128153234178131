import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { isEmptyObject } from 'Utils/Helpers';
import fetchClient from '../Services/fetchClient';

const ClientContext = React.createContext();

export function useClient() {
  return useContext(ClientContext);
}

export function ClientProvider({ children }) {
  const [clientIsLoading, setClientIsLoading] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [error, setError] = useState('');

  const client = useMemo(
    () => ({
      clientIsLoading,
      ...clientInfo,
      setClientInfo,
      setClientIsLoading,
      error,
    }),
    [clientIsLoading, clientInfo.clientid, error]
  );

  useEffect(() => {
    setClientIsLoading(true);
    fetchClient()
      .then((clientdata) => {
        setClientInfo(clientdata);
      })
      .catch((err) => {
        setError(err);
      })
      .then(() => setClientIsLoading(false));
  }, []);

  useEffect(() => {
    if (isEmptyObject(clientInfo)) return;
    setClientIsLoading(false);
  }, [clientInfo]);

  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
}

ClientProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
